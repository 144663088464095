
import React, { useEffect, useState } from "react";
import Main from "../components/layout/Main";
import { useLocation } from "react-router";
import useRequest from "../hooks/useRequest";

import { Severty, ShowToast } from "../helper/toast";
import Loader from "../components/Loader";
const CancelUrl = () => {






    return (

        <>



            <div className="login-sec customer-form-main">
                <div className="container p-0">
                    <div className="successPageOuter">
                        <div className="booking-form-inner succesOuter">
                            <div className="logo-wrap">
                                {/* <img src={LogoImg} alt="flomatic-logo" /> */}
                            </div>
                            <div className="successCardContent">
                                <div className="thank-you">
                                    <div className="icon">
                                        {/* <img src={ThankYou} alt="" /> */}
                                    </div>
                                    <div className="thankyou-cont">
                                        <h3>Your Payment has been Cancelled !</h3>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>




    )
}

export default CancelUrl