import { Row, Col, Modal, Form, Input, Select, InputNumber, Image } from "antd";
import React, { useState, useEffect } from "react";
import useRequest from "../../hooks/useRequest";
import { ShowToast, Severty } from "../../helper/toast";
import apiPath from "../../constants/apiPath";
import SingleImageUpload from "../../components/SingleImageUpload";
import notfound from "../../assets/images/not_found.png";
import { shortLang, longLang } from "../../config/language";

const AddFrom = ({ section, api, show, hide, data, refresh }) => {
  const [form] = Form.useForm();
  const { request } = useRequest();
  const [centerList, setCenterList] = useState([]);
  const [loading, setLoading] = useState(false);

  const getOrganizations = () => {
    request({
      url: apiPath.getExamBoard,
      method: "GET",
      onSuccess: (data) => {
        setCenterList(data.data.data);
      },
      onError: (error) => {
        console.log(error);
        ShowToast(error, Severty.ERROR);
      },
    });
  };

  useEffect(() => {
    getOrganizations();
    if (!data) return;

    form.setFieldsValue({ ...data, center_id: data?.center_id?._id });
  }, [data]);

  const onCreate = (values) => {
    const { name, center_id, course_code } = values;
    setLoading(true);
    const payload = {};
    payload.name = name;
    payload.course_code = course_code;
    payload.center_id = center_id;

    request({
      url: `${data ? api.addEdit + "/" + data._id : api.addEdit}`,
      method: "POST",
      data: payload,
      onSuccess: (data) => {
        setLoading(false);
        if (data.status) {
          ShowToast(data.message, Severty.SUCCESS);
          hide();
          refresh();
        } else {
          ShowToast(data.message, Severty.ERROR);
        }
      },
      onError: (error) => {
        ShowToast(error.response.data.message, Severty.ERROR);
        setLoading(false);
      },
    });
  };

  return (
    <Modal
      open={show}
      width={800}
      title={`${data ? "Update " + section : "Create a New " + section}`}
      okText="Ok"
      onCancel={hide}
      okButtonProps={{
        form: "create",
        htmlType: "submit",
        loading: loading,
      }}
    >
      <Form id="create" form={form} onFinish={onCreate} layout="vertical">
        <Row gutter={[16, 16]}>
          <Col span={24} sm={12}>
            <Form.Item
              className="mb-0"
              label={`Name`}
              name="name"
              rules={[
                { required: true, message: `Please enter the name!` },
                {
                  max: 200,
                  message: "Name should not contain more then 200 characters!",
                },
                {
                  min: 2,
                  message: "Name should contain atleast 2 characters!",
                },
              ]}
              normalize={(value) => value.trimStart()}
            >
              <Input autoComplete="off" placeholder={`Enter Name`} />
            </Form.Item>
          </Col>

          <Col span={24} sm={12}>
            <Form.Item
              className="mb-0"
              label={`Course Code`}
              name="course_code"
              rules={[
                { required: true, message: "Course Code must be provided!" },
                {
                  max: 200,
                  message:
                    "Course Code should not contain more then 200 characters!",
                },
                {
                  min: 2,
                  message: "Course Code should contain atleast 2 characters!",
                },
              ]}
              normalize={(value) => value.trimStart()}
            >
              <Input autoComplete="off" placeholder={`Enter Course Code`} />
            </Form.Item>
          </Col>

          <Col span={24} sm={12}>
            <Form.Item
              className="category-elt mb-0"
              name="center_id"
              label="Select Exam Board"
              rules={[{ required: true, message: "Missing Exam Board" }]}
            >
              <Select
                placeholder="Select Exam Board"
                value={data && data.center_id._id}
              >
                {centerList.length > 0
                  ? centerList.map((item, index) => (
                    <option
                      selected={data && data.center_id._id === item._id}
                      key={item._id}
                      value={item._id}
                    >
                      <span className="cap">{item.name}</span>
                    </option>
                  ))
                  : null}
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default AddFrom;
