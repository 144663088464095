import { Row, Col, Card, Button, Skeleton, Avatar, Image } from "antd";
import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import useRequest from "../../hooks/useRequest";
import { ShowToast, Severty } from "../../helper/toast";
import apiPath from "../../constants/apiPath";
import { Badge } from 'antd';
import moment from 'moment';

function View() {

  const sectionName = "View Preparation";
  const routeName = "view";

  const params = useParams();
  const { request } = useRequest();
  const [list, setList] = useState({});
  const [loading, setLoading] = useState(false);

  const fetchData = (id) => {
    setLoading(true);
    request({
      url: apiPath.viewEvent + "/" + id,
      method: 'GET',
      onSuccess: (data) => {
        console.log(data, "line26");
        setLoading(false);
        setList(data.data);
      },
      onError: (error) => {
        ShowToast(error, Severty.ERROR)
      }
    })
  }

  useEffect(() => {
    fetchData(params.id)
  }, [])

  return (
    <>
      <Row gutter={16}>
        <Col span={12} xs={24}>
          <Card title={sectionName + " Details"}>

            {loading ? [1, 2, 3].map(item => <Skeleton active key={item} />) :
              <div className="view-main-list">

                <div className="view-inner-cls">
                  <h5>Image:</h5>
                  <h6>
                    {list && list.name && !list.image ?
                      <Avatar style={{ backgroundColor: "#00a2ae", verticalAlign: 'middle' }} className="cap" size={50}> {list.name.charAt(0)} </Avatar> :
                      <Image className="image-radius" src={list.image} />
                    }
                  </h6>
                </div>

                <div className="view-inner-cls">
                  <h5>Name:</h5>
                  <h6><span className="cap">{list.name ? list.name : '-'}</span></h6>
                </div>

                <div className="view-inner-cls">
                  <h5>Code:</h5>
                  <h6><span className="cap">{list.name ? list.event_code : '-'}</span></h6>
                </div>

                <div className="view-inner-cls">
                  <h5>Type:</h5>
                  <h6><span className="cap">{list.event_type ? list.event_type : '-'}</span></h6>
                </div>

                <div className="view-inner-cls">
                  <h5> Location:</h5>
                  <h6><span className="cap">{list.location ? list.location : '-'}</span></h6>
                </div>

                <div className="view-inner-cls">
                  <h5>City:</h5>
                  <h6><span className="cap">{list.city ? list.city : '-'}</span></h6>
                </div>

                <div className="view-inner-cls">
                  <h5> Mode:</h5>
                  <h6><span className="cap">{list.name ? list.mode : '-'}</span></h6>
                </div>

                <div className="view-inner-cls">
                  <h5>Email Address:</h5>
                  <h6>{list.email ? list.email : '-'}</h6>
                </div>



                <div className="view-inner-cls">
                  <h5>Booking Fees:</h5>
                  <h6>{list.booking_fees ? list.booking_fees : "-"}</h6>
                </div>

                <div className="view-inner-cls">
                  <h5>Total Seats:</h5>
                  <h6>{list.total_seat ? list.total_seat : "-"}</h6>
                </div>

                <div className="view-inner-cls">
                  <h5>Booked Seat:</h5>
                  <h6>{list.booked_seat ? list.booked_seat : "-"}</h6>
                </div>

                <div className="view-inner-cls">
                  <h5>Available Seats:</h5>
                  <h6>{list.availableSeat ? list.availableSeat : "-"}</h6>
                </div>

                <div className="view-inner-cls">
                  <h5>Start Date:</h5>
                  <h6>{list.event_start_date ? moment(list.event_start_date).format("YYYY-MM-DD HH:mm") : "-"}</h6>
                </div>
                <div className="view-inner-cls">
                  <h5>End Date:</h5>
                  <h6>{list.event_end_date ? moment(list.event_end_date).format("YYYY-MM-DD HH:mm") : "-"}</h6>
                </div>
                <div className="view-inner-cls">
                  <h5>Booking Start Date:</h5>
                  <h6>{list.booking_start_date ? moment(list.booking_start_date).format("YYYY-MM-DD") : "-"}</h6>
                </div>
                <div className="view-inner-cls">
                  <h5>Booking End Date:</h5>
                  <h6>{list.booking_end_date ? moment(list.booking_end_date).format("YYYY-MM-DD") : "-"}</h6>
                </div>




                <div className="view-inner-cls">
                  <h5>Status:</h5>
                  <h6>{list.is_active ? <Badge status="success" text="Active" /> : <Badge status="error" text="InActive" />}</h6>
                </div>

                <div className="view-inner-cls">
                  <h5>Free:</h5>
                  <h6>{list.is_free ? <Badge status="success" text="Yes" /> : <Badge status="error" text="No" />}</h6>
                </div>

                <div className="view-inner-cls">
                  <h5>Description:</h5>
                  <h6 dangerouslySetInnerHTML={{ __html: list.description }}></h6>

                </div>



              </div>
            }

          </Card>

          <Card title={"Centre Details"} style={{ marginTop: "5px" }}>


            <div className="view-inner-cls">
              <h5>Name:</h5>
              <h6>{list?.center_id?.name ? list?.center_id?.name : "-"}</h6>
            </div>

            <div className="view-inner-cls">
              <h5>Profile Description:</h5>
              <h6>{list?.center_id?.profile_desc ? list?.center_id?.profile_desc : "-"}</h6>
            </div>

            <div className="view-inner-cls">
              <h5>Centre Code:</h5>
              <h6>{list?.center_id?.center_code ? list?.center_id?.center_code : "-"}</h6>
            </div>

            <div className="view-inner-cls">
              <h5>Email:</h5>
              <h6>{list?.center_id?.email ? list?.center_id?.email : "-"}</h6>
            </div>
            <div className="view-inner-cls">
              <h5>Location:</h5>
              <h6>{list?.center_id?.location ? list?.center_id?.location : "-"}</h6>
            </div>

            <div className="view-inner-cls">
              <h5>Status:</h5>
              <h6>{list.is_active ? <Badge status="success" text="Active" /> : <Badge status="error" text="InActive" />}</h6>
            </div>

            <div className="view-inner-cls float-right">
              <Link className="ant-btn ant-btn-primary" to={`/preparation`}>Back</Link>
            </div>
          </Card>
        </Col>
      </Row>
    </>
  );
}


export default View;
