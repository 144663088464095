import React, { useState, useEffect } from "react";
import { Card, Col, Row, Typography, Table, Tag } from "antd";
import { Link } from "react-router-dom";
import { PRICE } from "../../constants/conts";
import useRequest from "../../hooks/useRequest";
import { NavLink, useLocation } from "react-router-dom";
import { ShowToast, Severty } from "../../helper/toast";
import moment from "moment";
import apiPath from "../../constants/apiPath";
import Logo from "../../assets/images/Logo.svg";

function Home() {
  const { Title, Text } = Typography;
  const { request } = useRequest();
  const [loading, setLoading] = useState(false);
  const [requestQuotesList, setRequestQuotesList] = useState([]);
  const [bookingData, setBookingData] = useState([]);
  const [dashboard, setDashboard] = useState({
    customerCount: 0,
    specialistCount: 0,
    dealerCount: 0,
    typeCount: 0,
    makeCount: 0,
    modelCount: 0,
    variantCount: 0,
    productSaleCount: 0,
    productCatalogueCount: 0,
    requestQuoteCount: 0,
  });

  const count = [
    {
      today: "Number of Guardians",
      title: `${dashboard && dashboard.customerCount ? dashboard.customerCount : 0
        }`,
      persent: "10%",
      icon: <i class="fas fa-user-friends"></i>,
      bnb: "bnb2",
      url: "/guardian",
    },
    {
      today: "Number of Centres",
      title: `${dashboard && dashboard.centerCount ? dashboard.centerCount : 0
        }`,
      icon: <i class="fas fa-people-carry"></i>,
      bnb: "bnb2",
      url: "/centre",
    },
    {
      today: "Number of Courses",
      title: `${dashboard && dashboard.courseCount ? dashboard.courseCount : 0
        }`,
      icon: <i class="fas fa-user-tie"></i>,
      bnb: "bnb2",
      url: "/course",
    },
    // {
    //   today: "Number of Types",
    //   title: `${dashboard && dashboard.typeCount ? dashboard.typeCount : 0}`,
    //   icon: <i className="fa fa-car"></i>,
    //   bnb: "bnb2",
    //   url: '/vehicle/type'
    // },
    {
      today: "Number of Exams",
      title: `${dashboard && dashboard.examCount ? dashboard.examCount : 0}`,
      icon: <i class="fas fa-copyright"></i>,
      bnb: "bnb2",
      url: "/exam",
    },
    // {
    //   today: "Number of Conducted Exams",
    //   title: `${dashboard && dashboard.examConductsCount
    //     ? dashboard.examConductsCount
    //     : 0
    //     }`,
    //   icon: <i class="fas fa-quote-right"></i>,
    //   bnb: "bnb2",
    //   url: "/exam-conduct",
    // },
  ];

  const bookingColoum = [
    {
      title: "Booking No ",
      render: (_, { bookingNo }) => {
        return <div>{bookingNo ? bookingNo : "--"}</div>;
      },
    },
    {
      title: "User Name",
      render: (_, { user_id }) => {
        return <div>{user_id ? user_id.name : "--"}</div>;
      },
    },

    {
      title: "type",
      render: (_, { type }) => {
        return <div>{type}</div>;
      },
    },
    {
      title: "Quantity",
      render: (_, { quantity, student }) => {
        return <div>{quantity ? quantity : student.length}</div>;
      }
    },
    {
      title: "Amount",
      render: (_, { totalAmount }) => {
        return <div>{totalAmount ? totalAmount.toFixed(2) : "--"}</div>;
      }
    },
    {
      title: "Booking date",
      key: "created_at",
      dataIndex: "created_at",
      render: (_, { created_at }) => {
        return new Date(created_at).toLocaleDateString();
      },
    },
  ];

  useEffect(() => {
    setLoading(true);
    request({
      url: apiPath.dashboard,
      method: "GET",
      onSuccess: (data) => {
        setLoading(false);
        setDashboard(data.data);
        setBookingData(data?.data.booking);
        setRequestQuotesList(data?.data.requestedQuoteList);
      },
      onError: (error) => {
        setLoading(false);
        ShowToast(error, Severty.ERROR);
      },
    });
  }, []);

  return (
    <>
      <div className="layout-content">
        <div className="mb-24">
          <Row gutter={[24, 0]}>
            {/* <Col xs={24} sm={6} md={3} className="logoOuterMain">
              <div className="logoOuter"> <img src={Logo} alt="Logo" /> </div>
            </Col> */}

            <Col xs={24} sm={24} md={24}>
              <Row
                className="rowgap-vbox"
                gutter={[24, 16]}
                style={{ marginLeft: "0" }}
              >
                {count.map((c, index) => (
                  <Col
                    key={index}
                    xs={24}
                    sm={24}
                    md={12}
                    lg={12}
                    xl={8}
                    xxl={6}
                    className="mb24"
                    style={{ paddingRight: "0px" }}
                  >
                    <Link to={c.url}>
                      <Card
                        bordered={false}
                        className="criclebox"
                        style={{ height: "100%" }}
                      >
                        <div className="number">
                          <Row align="middle" gutter={[24, 0]}>
                            <Col xs={18}>
                              <span>{c.today}</span>
                              <Title level={3}>{c.title}</Title>
                            </Col>
                            <Col xs={6}>
                              {" "}
                              <div className="icon-box">{c.icon}</div>
                            </Col>
                          </Row>
                        </div>
                      </Card>
                    </Link>
                  </Col>
                ))}
              </Row>
            </Col>
          </Row>
        </div>

        <Row className="mt-3" gutter={[24, 0]}>
          <Col span={24}>
            <Card bordered={false} className="criclebox tablespace mb-24">
              <p className="dashboard-table-heading">
                Recent  (10) Booking{" "}
              </p>
              <div className="table-responsive">
                <Table
                  loading={loading}
                  columns={bookingColoum}
                  dataSource={bookingData}
                  pagination={false}
                  className="ant-border-space"
                />
              </div>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default Home;
