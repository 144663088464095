import { Row, Col, Modal, Form, Input, Select, DatePicker } from "antd";
import React, { useState, useEffect } from "react";
import useRequest from "../../hooks/useRequest";
import { ShowToast, Severty } from "../../helper/toast";
import apiPath from "../../constants/apiPath";
import moment from "moment";

const AddFrom = ({ section, api, show, hide, data, refresh }) => {
  const [form] = Form.useForm();
  const { request } = useRequest();
  const [centerList, setCenterList] = useState([]);
  const [loading, setLoading] = useState(false);
  console.log(data);

  const getOrganizations = () => {
    request({
      url: apiPath.getCenters,
      method: "GET",
      onSuccess: (data) => {
        setCenterList(data.data.list);
      },
      onError: (error) => {
        console.log(error);
        ShowToast(error, Severty.ERROR);
      },
    });
  };

  useEffect(() => {
    getOrganizations();
    if (!data) return;

    form.setFieldsValue({ ...data, center_id: data.center_id?._id });
  }, [data]);

  const onCreate = (values) => {
    const { name, code } = values;
    setLoading(true);

    const payload = {};
    payload.name = name;
    payload.code = code;

    request({
      url: `${data ? api.addEdit + "/" + data._id : api.addEdit}`,
      method: "POST",
      data: payload,
      onSuccess: (data) => {
        setLoading(false);
        if (data.status) {
          ShowToast(data.message, Severty.SUCCESS);
          hide();
          refresh();
        } else {
          ShowToast(data.message, Severty.ERROR);
        }
      },
      onError: (error) => {
        ShowToast(error.response.data.message, Severty.ERROR);
        setLoading(false);
      },
    });
  };

  return (
    <Modal
      open={show}
      width={800}
      title={`${data ? "Update " + section : "Create a New " + section}`}
      okText="Ok"
      onCancel={hide}
      okButtonProps={{
        form: "create",
        htmlType: "submit",
        loading: loading,
      }}
    >
      <Form id="create" form={form} onFinish={onCreate} layout="vertical">
        <Row gutter={[16, 16]}>
          <Col span={24} sm={12}>
            <Form.Item
              className="mb-0"
              label={`Name`}
              name="name"
              rules={[
                { required: true, message: `Please enter the name!` },
                {
                  max: 200,
                  message: "Name should not contain more then 200 characters!",
                },
                {
                  min: 2,
                  message: "Name should contain atleast 2 characters!",
                },
              ]}
              normalize={(value) => value.trimStart()}
            >
              <Input autoComplete="off" placeholder={`Enter Name`} />
            </Form.Item>
          </Col>

          <Col span={24} sm={12}>
            <Form.Item
              className="mb-0"
              label={`Code`}
              name="code"
              rules={[
                { required: true, message: "Code must be provided!" },
                {
                  max: 200,
                  message:
                    " Code should not contain more then 200 characters!",
                },
                {
                  min: 2,
                  message: " Code should contain atleast 2 characters!",
                },
              ]}
              normalize={(value) => value.trimStart()}
            >
              <Input autoComplete="off" placeholder={`Enter  Code`} />
            </Form.Item>
          </Col>


        </Row>
      </Form>
    </Modal>
  );
};

export default AddFrom;
