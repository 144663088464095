import { Row, Col, Card, Table, Button, Modal, Form, Input, Tag, Select, Tooltip, message, InputNumber, Image } from "antd";
import React, { useState, useEffect } from "react";
import ShowTotal from "../../components/ShowTotal";
import useRequest from "../../hooks/useRequest";
import { ShowToast, Severty } from "../../helper/toast";
import { ExclamationCircleOutlined } from '@ant-design/icons';
import apiPath from "../../constants/apiPath";
import moment from "moment";
import useDebounce from "../../hooks/useDebounce";
import ConfirmationBox from "../../components/ConfirmationBox";
import SingleImageUpload from "../../components/SingleImageUpload";
import notfound from "../../assets/images/not_found.png";
import { shortLang, longLang } from "../../config/language";
import { DownloadExcel, SampleFileDownload } from "../../components/ExcelFile";
import ImportForm from "../../components/ImportForm";
import AddFrom from "./Add";
import { useNavigate } from "react-router";

const Search = Input.Search;

function Index() {

  const sectionName = "Subject";
  const routeName = "syllabus";

  const api = {
    status: apiPath.statusSyllabus,
    addEdit: apiPath.addEditSyllabus,
    list: apiPath.listSyllabus,
    importFile: apiPath.importSyllabus
  }

  const { request } = useRequest()
  const [list, setList] = useState([])
  const [loading, setLoading] = useState(false)
  const [refresh, setRefresh] = useState(false)
  const [visible, setVisible] = useState(false);
  const [selected, setSelected] = useState();
  const [searchText, setSearchText] = useState('');
  const [importVisible, setImportVisible] = useState(false);
  const debouncedSearchText = useDebounce(searchText, 300);
  const [pagination, setPagination] = useState({ current: 1, pageSize: 10 });
  const { showConfirm } = ConfirmationBox();
  const navigate = useNavigate();

  const columns = [
    {
      title: "No",
      dataIndex: "s_no",
      key: "s_no",
      render: (_, { }, index) => <span>{index + 1}</span>
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (_, { name }) => {
        return (
          <span className="cap">{name ? name : '-'}</span>
        )
      },
      sorter: (a, b) => {
        let nameA = a.name.toLowerCase();
        let nameB = b.name.toLowerCase();
        if (nameA < nameB)
          return -1;
        if (nameA > nameB)
          return 1;
        return 0;
      },
    },
    {
      title: "Course",
      dataIndex: "course_id",
      key: "course_id",
      render: (_, { course_id }) => {
        return (
          <span className="cap">{course_id ? course_id.name : '-'}</span>
        )
      },
      sorter: (a, b) => {
        let nameA = a.course_id.name.toLowerCase();
        let nameB = b.course_id.name.toLowerCase();
        if (nameA < nameB)
          return -1;
        if (nameA > nameB)
          return 1;
        return 0;
      },
    },
    {
      title: "Exam",
      dataIndex: "exam_id",
      key: "exam_id",
      render: (_, { exam_id }) => {
        return (
          <span className="cap">{exam_id ? exam_id.name : '-'}</span>
        )
      },
      sorter: (a, b) => {
        let nameA = a.exam_id.name.toLowerCase();
        let nameB = b.exam_id.name.toLowerCase();
        if (nameA < nameB)
          return -1;
        if (nameA > nameB)
          return 1;
        return 0;
      },
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      render: (_, { description }) => {
        return (
          <span className="cap">{description ? description : '-'}</span>
        )
      }
    },
    {
      title: "Status",
      key: "is_active",
      filters: [
        {
          text: 'Active',
          value: true,
        },
        {
          text: 'Inactive',
          value: false,
        },
      ],
      render: (_, { is_active, _id }) => {
        let color = is_active ? 'green' : 'red';
        return (<a><Tag onClick={(e) => showConfirm({ record: _id, path: api.status, onLoading: () => setLoading(true), onSuccess: () => setRefresh(prev => !prev) })} color={color} key={is_active}>{is_active ? "Active" : "Inactive"}</Tag></a>);
      },
    },
    {
      title: "Created On",
      key: "created_at",
      dataIndex: "created_at",
      render: (_, { created_at }) => {
        return (
          moment(created_at).format('DD-MMM-YYYY')
        );
      },
      sorter: (a, b) => {
        let nameA = a.created_at;
        let nameB = b.created_at;
        if (nameA < nameB)
          return -1;
        if (nameA > nameB)
          return 1;
        return 0;
      },
    },
    {
      title: "Action",
      fixed: 'right',
      render: (_, record) => {
        return (
          <>
            <Tooltip title={"Update " + sectionName} color={"purple"} key={"update" + routeName}>
              <Button title="Edit" onClick={() => {
                setSelected(record)
                setVisible(true)
              }}>
                <i className="fa fa-light fa-pen"></i>
              </Button>
            </Tooltip>
            {/* 
            <Tooltip title={"Add Options"} color={"purple"} key={"add_options"}>
              <Button title="Add Options" onClick={() => {
                navigate(`/syllabus/${record._id}/options`);
              }}>
                <i className="fa fa-light fa-plus"></i>
              </Button>
            </Tooltip> */}
          </>
        );
      },
    },
  ];

  useEffect(() => {
    setLoading(true)
    fetchData(pagination)
  }, [refresh, debouncedSearchText])


  const fetchData = (pagination, filters) => {
    const filterActive = filters ? filters.is_active : null
    request({
      url: api.list + `?status=${filterActive ? filterActive.join(',') : ''}&page=${pagination ? pagination.current : 1}&limit=${pagination ? pagination.pageSize : 10}&search=${debouncedSearchText}`,
      method: 'GET',
      onSuccess: (data) => {
        setLoading(false)
        setList(data.data.list.docs)
        setPagination(prev => ({ current: pagination.current, total: data.data.list.totalDocs }))
      },
      onError: (error) => {
        setLoading(false)
        ShowToast(error, Severty.ERROR)
      }
    })
  }

  const onSearch = (e) => {
    setSearchText(e.target.value)
    setPagination({ current: 1 })
  };

  const handleChange = (pagination, filters) => {
    fetchData(pagination, filters);
  }

  const excelData = list.map(row => ({
    "Image": row.image ? row.image : '-',
    "Organizer": row.category ? row.category.name : '-',
    "Name": row.name ? row.name : '-',
    "Status": row.is_active ? 'Active' : 'Inactive',
    "Created On": moment(row.created_at).format("DD-MM-YYYY"),
  }));

  return (
    <>
      <div className="tabled categoryService">
        <Row gutter={[24, 0]}>
          <Col xs="24" xl={24}>
            <Card
              bordered={false}
              className="criclebox tablespace mb-24"
              title={sectionName + " Management"}
              extra={
                <>
                  <Search
                    allowClear
                    size="large"
                    onChange={onSearch}
                    value={searchText}
                    onPressEnter={onSearch}
                    placeholder="Search By Subject"
                  />
                  <div className="button_group justify-content-end w-100">
                    <Button onClick={(e) => { setVisible(true); setSearchText(''); }}>Add {sectionName}</Button>
                    {/* <Button title="Export" onClick={(e) => DownloadExcel(excelData, sectionName)}><i class="fas fa-cloud-download-alt"></i>&nbsp;&nbsp;Export</Button>
                    <Button title="Import" onClick={(e) => { setImportVisible(true); setSearchText(''); }}><i class="fas fa-cloud-upload-alt"></i>&nbsp;&nbsp;Import</Button> */}
                  </div>
                </>
              }
            >

              <h4 className="text-right mb-1">{pagination.total ? ShowTotal(pagination.total) : ShowTotal(0)}</h4>
              <div className="table-responsive customPagination">
                <Table
                  loading={loading}
                  columns={columns}
                  dataSource={list}
                  pagination={{ defaultPageSize: 10, responsive: true, total: pagination.total, showSizeChanger: true, pageSizeOptions: ['10', '20', '30', '50'] }}
                  onChange={handleChange}
                  className="ant-border-space"
                />
              </div>
            </Card>
          </Col>
        </Row>
      </div>

      {visible && <AddFrom section={sectionName} api={api} show={visible} hide={() => { setSelected(); setVisible(false) }} data={selected} refresh={() => setRefresh(prev => !prev)} />}
      {importVisible && <ImportForm path={api.importFile} sectionName={sectionName} show={importVisible} hide={() => setImportVisible(false)} existingData={list} refresh={() => setRefresh(prev => !prev)} />}
    </>
  );
}

export default Index;
