import { Button, Modal } from "antd";
import { ShowToast, Severty } from "../helper/toast";
import { ExclamationCircleOutlined } from '@ant-design/icons';
import useRequest from "../hooks/useRequest";
const { confirm } = Modal;

const ConfirmationBox = () => {

  const { request } = useRequest();

  const showConfirm = ({ record, path, onLoading, onSuccess, type }) => {
    setTimeout(() => {
      confirm({
        icon: <ExclamationCircleOutlined />,
        content: <Button >Are you sure you want to change the status ?</Button>,
        onOk() {
          statusChange(record, path, onLoading, onSuccess, type)
        },
        onCancel() {
          console.log('Cancel');
        },
      });
    }, 5);
  };

  const statusChange = (record, path, onLoading, onSuccess, type) => {
    onLoading(true);
    let url = '';
    if (!!type) {
      url = path + "/" + type + "/" + record
    } else {
      url = path + "/" + record
    }
    request({
      url: url,
      method: 'GET',
      onSuccess: (data) => {
        onSuccess();
        onLoading(false);
        ShowToast(data.message, Severty.SUCCESS)
      },
      onError: (error) => {
        console.log(error)
        ShowToast(error, Severty.ERROR)
      }
    })
  };

  return { showConfirm }

};

export default ConfirmationBox;