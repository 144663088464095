import { Row, Col, Radio, Modal, Form, Input, Select } from "antd";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import React, { useState, useEffect, useRef } from "react";
import useRequest from "../../hooks/useRequest";
import LocationMap from "./LocationMap";
import { ShowToast, Severty } from "../../helper/toast";

const StudentForm = ({ type, path, sectionName, show, hide, data, refresh }) => {
  const [form] = Form.useForm();
  const { request } = useRequest();
  const [loading, setLoading] = useState(false);
  const [mobileNumber, setMobileNumber] = useState({ mobile: '', country_code: '' });
  const [latLong, setlatLong] = useState({ lat: 30.5595, lng: 22.9375 });
  const [location, setLocation] = useState();
  const [dealIn, setDealIn] = useState('Products');
  const [userAddress, setUserAddress] = useState(null);
  const [userList, setUserList] = useState([]);
  const handleChange = (value, data, event, formattedValue) => {
    var country_code = data.dialCode;
    setMobileNumber({ country_code: country_code, mobile: value.slice(data.dialCode.length) });
  }

  const handleLocationChange = (value) => {
    setUserAddress(value.address);
    setlatLong({ lat: parseFloat(value.latitude), lng: parseFloat(value.longitude) });
  }

  const handleDealInChange = (value) => {
    setDealIn(value)
  }

  const getList = () => {

    request({
      url: `/common/user-list/Guardian`,
      method: 'PUT',
      onSuccess: (data) => {
        // console.log(data.data.data)
        setUserList(data.data.data)
      },
      onError: (error) => {
        console.log(error)
        ShowToast(error, Severty.ERROR)
      }
    })
  };


  useEffect(() => {
    getList()
  }, [])

  useEffect(() => {
    if (!data) return;
    form.setFieldsValue({ ...data, guardian_id: data.guardian_id && data.guardian_id._id });
  }, [data])

  const onCreate = (values) => {
    const { first_name, last_name, email, guardian_id } = values
    setLoading(true);
    const payload = {}
    payload.first_name = first_name;
    payload.last_name = last_name;
    payload.name = first_name + " " + last_name;
    payload.email = email;
    payload.latitude = latLong.lat ? latLong.lat : null;
    payload.longitude = latLong.lng ? latLong.lng : null;
    payload.location = userAddress ? userAddress : location;
    payload.type = "Student"
    payload.guardian_id = guardian_id

    request({
      url: `${data ? path + "/" + data._id : path}`,
      method: 'POST',
      data: payload,
      onSuccess: (data) => {
        setLoading(false);
        if (data.status) {
          ShowToast(data.message, Severty.SUCCESS);
          hide();
          refresh();
        } else {
          ShowToast(data.message, Severty.ERROR);
        }
      },
      onError: (error) => {
        ShowToast(error.response.data.message, Severty.ERROR);
        setLoading(false);
      },
    })
  };

  return (
    <Modal
      width={1100}
      open={show}
      title={`${data ? 'Update ' + sectionName : 'Create a New ' + sectionName}`}
      okText="Ok"
      onCancel={hide}
      okButtonProps={{
        form: 'create',
        htmlType: 'submit',
        loading: loading,
      }}
    >
      <Form id="create" form={form} initialValues={{ deal_in: "Products" }} onFinish={onCreate} layout="vertical">
        <Row gutter={[16, 16]}>
          <Col span={24} lg={8} sm={12}>
            <Form.Item className="mb-1"
              label="First Name"
              name="first_name"
              normalize={value => value.trimStart()}
              rules={[
                { required: true, message: "Please Enter the name!" },
                { max: 200, message: "First Name should not contain more then 200 characters!" },
                { min: 2, message: "First Name should contain at least 2 characters!" },
                { pattern: new RegExp(/^[a-z A-Z ]*$/), message: "Only Alphabetic Characters Allowed!" }
              ]}
            >
              <Input autoComplete="off" placeholder="Enter Name" />
            </Form.Item>
          </Col>

          <Col span={24} lg={8} sm={12}>
            <Form.Item className="mb-1"
              label="Last Name"
              name="last_name"
              normalize={value => value.trimStart()}
              rules={[
                { required: true, message: "Please Enter the last name!" },
                { max: 200, message: "Last Name should not contain more then 200 characters!" },
                { min: 2, message: "Last Name should contain at least 2 characters!" },
                { pattern: new RegExp(/^[a-z A-Z ]*$/), message: "Only Alphabetic Characters Allowed!" }
              ]}
            >
              <Input autoComplete="off" placeholder="Enter Name" />
            </Form.Item>
          </Col>

          <Col span={24} lg={8} sm={12}>
            <Form.Item className="mb-1"
              label="Email Address"
              name="email"
              rules={[
                { type: 'email', message: 'The email is not a valid email!' },
                { required: true, message: "Please enter the email!" },
                { max: 500, message: "Email should not contain more then 500 characters!" },
                { min: 5, message: "Email should contain at least 5 characters!" },
                {
                  pattern: new RegExp(/^([a-zA-Z0-9._%-]*[a-zA-Z]+[a-zA-Z0-9._%-]*@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/),
                  message: "Enter valid email!"
                }
              ]}
            >
              <Input autoComplete="off" placeholder="Enter Email Address" />
            </Form.Item>
          </Col>
          <Col span={24} lg={8} sm={12}>
            <Form.Item className="mb-1"
              name="guardian_id"
              label="Select Guardian"
            >
              <Select placeholder="Select Guardian">
                {userList.length > 0 ? userList.map((item) => (
                  <option key={item._id} value={item._id}>
                    <span className="cap">{item.name}</span>
                  </option>
                )) : null}
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  )
};

export default StudentForm;