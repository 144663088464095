import { Row, Col, Card, Skeleton, Form, Badge, Button } from "antd";
import moment from "moment";
import { Link, useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import useRequest from "../hooks/useRequest";

const PrivacyPolicy = () => {
    const [forms] = Form.useForm();
    const { request } = useRequest();
    const { slug } = useParams();
    const [loading, setLoading] = useState(false);
    const [privacy, setPrivacy] = useState({});
    const [selected, setSelected] = useState();
    const [refresh, setRefresh] = useState(false);
    const routeName = "content";
    const navigate = useNavigate();

    // call api in use effective
    useEffect(() => {
        if (!slug) return;
        console.log(slug);
        request({
            url: "/common/get-content/" + slug,
            method: "GET",
            onSuccess: (data) => {
                setPrivacy(data.data);
                setSelected(data.data);
            },
        });
    }, [refresh, slug]);
    return (
        <Card>
            <Row gutter={16}>
                <Col span={12} xs={24} md={24}>
                    {loading ? (
                        <Skeleton active />
                    ) : (
                        <>
                            <div className="">
                                <h6 className="cap">
                                    {
                                        <p
                                            dangerouslySetInnerHTML={{
                                                __html: privacy.description || "-",
                                            }}
                                        ></p>
                                    }
                                </h6>
                            </div>


                        </>
                    )}
                </Col>
            </Row>
        </Card>
    );
};

export default PrivacyPolicy;