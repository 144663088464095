import { Row, Col, Card, Table, Button, Modal, Form, Input, Tag, Select, Tooltip, message, InputNumber, Image, DatePicker, Switch } from "antd";
import React, { useState, useEffect } from "react";
import ShowTotal from "../../components/ShowTotal";
import useRequest from "../../hooks/useRequest";
import { ShowToast, Severty } from "../../helper/toast";
import { ExclamationCircleOutlined } from '@ant-design/icons';
import apiPath from "../../constants/apiPath";
import moment from "moment";
import useDebounce from "../../hooks/useDebounce";
import ConfirmationBox from "../../components/ConfirmationBox";
import SingleImageUpload from "../../components/SingleImageUpload";
import notfound from "../../assets/images/not_found.png";
import { shortLang, longLang } from "../../config/language";
import { DownloadExcel, SampleFileDownload } from "../../components/ExcelFile";
import ImportForm from "../../components/ImportForm";
import AddFrom from "../ExamConduct/Add";
import { eventCategory, eventMode, eventType } from "../../constants/conts";
import DescriptionEditor from "../../components/DescriptionEditor";
import { useNavigate, useParams } from "react-router";
import LocationMap from "../User/LocationMap";

const Search = Input.Search;

function Index() {

    const sectionName = "AddEvent";
    const routeName = "add-event";

    const api = {
        status: apiPath.statusEvent,
        addEdit: apiPath.addEditEvent,
        list: apiPath.listCenter
    }

    const { request } = useRequest()
    const [center, setCenter] = useState()
    const [centerList, setCenterList] = useState([])
    const [loading, setLoading] = useState(false)
    const [refresh, setRefresh] = useState(false)
    const [searchText, setSearchText] = useState('');
    const debouncedSearchText = useDebounce(searchText, 300);
    const [pagination, setPagination] = useState({ current: 1, pageSize: 10 });
    const [form] = Form.useForm();
    const [file, setFile] = useState([]);
    const [image, setImage] = useState([]);
    const [editorValue, setEditorValue] = useState('');
    const [editorValue2, setEditorValue2] = useState('');
    const [isFree, setIsFree] = useState(false);
    const [tnc, setTnc] = useState(false);
    const [cityId, setCityId] = useState();
    const [latLong, setlatLong] = useState({ lat: 30.5595, lng: 22.9375 });
    const [userAddress, setUserAddress] = useState(null);
    const { id } = useParams();
    const navigate = useNavigate();
    const FileType = ["image/png", "image/jpg", "image/jpeg", "image/avif", "image/webp", "image/gif"]
    const [eventStartDate, setEventStartDate] = useState(null);
    const [eventEndDate, setEventEndDate] = useState(null);
    const [bookingStartDate, setBookingStartDate] = useState(null);
    const disabledBookingStartDate = (current) => {
        return (
            current &&
            (current < moment().startOf("day") || current >= eventStartDate)
        );
    };

    const disabledBookingEndDate = (current) => {
        return (
            current &&
            (current <= bookingStartDate || current >= eventStartDate)
        );
    };

    const disabledEventEndDate = (current) => {
        return current && current <= eventStartDate;
    };
    const handleLocationChange = (value) => {
        setUserAddress(value.address);
        setlatLong({
            lat: parseFloat(value.latitude),
            lng: parseFloat(value.longitude),
        });
        setCityId(value.city);
        console.log(value)
    };
    const handleCancle = () => {
        navigate("/event");
    };
    const handleImage = (data) => {
        setImage(data);
        setFile(data[0]?.url);
    }

    const handleIsFreeChange = (checked) => {
        setIsFree(checked);
    };

    const handleTncChange = (checked) => {
        setTnc(checked);
    };

    const handleEditorChange = (data) => {
        setEditorValue(data);
    }

    const handleEditorChange2 = (data) => {
        setEditorValue2(data);
    }

    const getCenters = () => {
        request({
            url: api.list + `?search=${debouncedSearchText}`,
            method: 'GET',
            onSuccess: (data) => {
                setLoading(false)
                setCenterList(data.data.list.docs)
                setPagination(prev => ({ current: pagination.current, total: data.data.list.totalDocs }))
            },
            onError: (error) => {
                setLoading(false)
                ShowToast(error, Severty.ERROR)
            }
        })
    }

    useEffect(() => {
        setLoading(true)
        getCenters(pagination)
    }, [refresh, debouncedSearchText])

    const onCreate = (values) => {
        const { name, email, type, mode, start_date, end_date, booking_start_date, booking_end_date, booking_fees, total_seat, event_type, event_end_date, event_start_date } = values
        if (editorValue === "" || !editorValue || editorValue === "<p></p>\n") {
            return ShowToast("Please add the description", Severty.ERROR);
        }
        setLoading(true)
        const payload = {}
        payload.name = name;
        payload.type = "Event";
        payload.description = editorValue
        payload.mode = mode
        payload.email = email
        payload.start_date = start_date
        payload.end_date = end_date
        payload.booking_start_date = booking_start_date
        payload.booking_end_date = booking_end_date
        payload.event_end_date = event_end_date
        payload.event_start_date = event_start_date
        payload.total_seat = total_seat
        payload.is_free = isFree
        payload.booking_fees = booking_fees
        payload.tnc = tnc
        payload.tnc_description = editorValue2
        payload.image = image && image.length > 0 ? image[0].url : '';
        payload.latitude = latLong.lat ? latLong.lat : null;
        payload.longitude = latLong.lng ? latLong.lng : null;
        payload.location = userAddress ? userAddress : null;
        payload.city = cityId ? cityId : null;
        payload.event_type = event_type ? event_type : null;
        payload.center_id = center

        request({
            url: `${id ? api.addEdit + "/" + id : api.addEdit}`,
            method: 'POST',
            data: payload,
            onSuccess: (data) => {
                setLoading(false)
                if (data.status) {
                    ShowToast(data.message, Severty.SUCCESS);
                    navigate(`/event`)
                } else {
                    ShowToast(data.message, Severty.ERROR);
                }
            },
            onError: (error) => {
                ShowToast(error.response.data.message, Severty.ERROR)
                setLoading(false)
            },
        })
    };


    return (
        <>
            <div className=" ">
                <Card
                    bordered={false}
                    className="criclebox tablespace mb-24"
                    title={sectionName + " Management"}
                >
                    <Form id="create"
                        style={{ padding: 7 }}
                        layout="vertical"
                        form={form}
                        onFinish={onCreate}
                    >
                        <Row style={{ marginBottom: 30 }} gutter={[24, 16]}>
                            <Col span={24} sm={24}>
                                <Form.Item className="category-elt mb-0"
                                    name="center_id"
                                    label="Select Centre"
                                >
                                    <Select onChange={(val) => setCenter(val)} placeholder="Select Centre">
                                        {centerList.length > 0 ? centerList.map((item) => (
                                            <option key={item._id} value={item._id}>
                                                <span className="cap">{item.name}</span>
                                            </option>
                                        )) : null}
                                    </Select>
                                </Form.Item>
                            </Col>

                        </Row>

                        {center ?
                            <Row style={{ padding: 15 }} gutter={[24, 16]}>
                                <Col span={24} md={12}>
                                    <Form.Item
                                        className="mb-0"
                                        label={`Event Name`}
                                        name="name"
                                        rules={[
                                            { required: true, message: "Event Name must be provided!" },
                                            {
                                                max: 200,
                                                message:
                                                    "Event Name should not contain more then 200 characters!",
                                            },
                                            {
                                                min: 2,
                                                message:
                                                    "Event Name should contain atleast 2 characters!",
                                            },
                                        ]}
                                    >
                                        <Input autoComplete="off" placeholder={`Enter Event Name`} />
                                    </Form.Item>
                                </Col>

                                <Col span={24} md={6}>
                                    <Form.Item
                                        className="category-elt mb-0"
                                        name="event_type"
                                        label="Select Type"
                                        rules={[
                                            {
                                                required: true,
                                                message: "Please select Type!",
                                            },
                                        ]}
                                    >
                                        <Select placeholder="Select Type">
                                            {eventType.map((item) => (
                                                <option key={item.value} value={item.value}>
                                                    <span className="cap">{item.label}</span>
                                                </option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </Col>

                                <Col span={24} md={6}>
                                    <Form.Item
                                        className="category-elt mb-0"
                                        name="mode"
                                        label="Select Mode"
                                        rules={[
                                            {
                                                required: true,
                                                message: "Please select Mode!",
                                            },
                                        ]}
                                    >
                                        <Select placeholder="Select Mode">
                                            {eventMode.map((item) => (
                                                <option key={item.value} value={item.value}>
                                                    <span className="cap">{item.label}</span>
                                                </option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </Col>

                                <Col span={24} sm={6}>
                                    <Form.Item
                                        className="category-elt mb-0"
                                        label="Event Start Date"
                                        name="event_start_date"
                                        rules={[
                                            {
                                                required: true,
                                                message: "Please select a Event Start Date",
                                            },
                                        ]}
                                    >
                                        <DatePicker
                                            format="YYYY-MM-DD HH:mm"
                                            placeholder="Enter Event Start Date"
                                            onChange={(date) => setEventStartDate(date)}
                                            showTime
                                            disabledDate={(current) => current && current < moment().startOf("minute")}
                                        />
                                    </Form.Item>
                                </Col>

                                <Col span={24} sm={6}>
                                    <Form.Item
                                        className="category-elt mb-0"
                                        label="Event End Date"
                                        name="event_end_date"
                                        rules={[
                                            {
                                                required: true,
                                                message: "Please select a Event End Date",
                                            },
                                            ({ getFieldValue }) => ({
                                                validator(_, value) {
                                                    if (!value || value > eventStartDate) {
                                                        return Promise.resolve();
                                                    }
                                                    return Promise.reject(
                                                        new Error("Event End Date must be after Event Start Date")
                                                    );
                                                },
                                            }),
                                        ]}
                                    >
                                        <DatePicker
                                            format="YYYY-MM-DD HH:mm"
                                            showTime
                                            placeholder="Enter Event End Date"
                                            disabledDate={disabledEventEndDate}
                                            onChange={(date) => setEventEndDate(date)}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={24} sm={6}>
                                    <Form.Item
                                        className="category-elt mb-0"
                                        label="Booking Start Date"
                                        name="booking_start_date"
                                        rules={[
                                            {
                                                required: true,
                                                message: "Please select a Booking Start Date",
                                            },
                                        ]}
                                    >
                                        <DatePicker
                                            format="YYYY-MM-DD"
                                            placeholder="Enter Booking Start Date"
                                            disabledDate={disabledBookingStartDate}
                                            onChange={(date) => setBookingStartDate(date)}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={24} sm={6}>
                                    <Form.Item
                                        className="category-elt mb-0"
                                        label="Booking End Date"
                                        name="booking_end_date"
                                        rules={[
                                            {
                                                required: true,
                                                message: "Please select a Booking End Date",
                                            },
                                        ]}
                                    >
                                        <DatePicker
                                            format="YYYY-MM-DD"
                                            placeholder="Enter Booking End Date"
                                            disabledDate={disabledBookingEndDate} // Custom validation for Booking End Date
                                        />
                                    </Form.Item>
                                </Col>

                                {/* <Col span={24} md={6}>
                                  <Form.Item
                                      className="mb-0"
                                      label="Event Start Date"
                                      name="event_start_date"
                                      rules={[
                                          {
                                              required: true,
                                              message: "Event Start Date must be provided!",
                                          },
                                      ]}
                                  >
                                      <Input
                                          type="date"
                                          placeholder="Enter Event Start Date"
                                          min={today}
                                          onChange={(e) =>
                                              handleDateChange("event_start_date", e.target.value)
                                          }
                                      />
                                  </Form.Item>
                              </Col>
  
                              <Col span={24} md={6}>
                                  <Form.Item
                                      className="mb-0"
                                      label="Event End Date"
                                      name="event_end_date"
                                      rules={[
                                          {
                                              required: true,
                                              message: "Event End Date must be provided!",
                                          },
                                      ]}
                                  >
                                      <Input
                                          type="date"
                                          placeholder="Enter Event End Date"
                                          min={eventStartDate || today}
                                          disabled={!eventStartDate}
                                          onChange={(e) =>
                                              handleDateChange("event_end_date", e.target.value)
                                          }
                                      />
                                  </Form.Item>
                              </Col>
  
                              <Col span={24} md={6}>
                                  <Form.Item
                                      className="mb-0"
                                      label="Booking Start Date"
                                      name="booking_start_date"
                                      rules={[
                                          {
                                              required: true,
                                              message: "Booking Start Date must be provided!",
                                          },
                                      ]}
                                  >
                                      <Input
                                          type="date"
                                          placeholder="Enter Booking Start Date"
                                          min={today}
                                          max={eventStartDate}
                                          disabled={!eventStartDate}
                                          onChange={(e) =>
                                              handleDateChange("booking_start_date", e.target.value)
                                          }
                                      />
                                  </Form.Item>
                              </Col>
  
                              <Col span={24} md={6}>
                                  <Form.Item
                                      className="mb-0"
                                      label="Booking End Date"
                                      name="booking_end_date"
                                      rules={[
                                          {
                                              required: true,
                                              message: "Booking End Date must be provided!",
                                          },
                                      ]}
                                  >
                                      <Input
                                          type="date"
                                          placeholder="Enter Booking End Date"
                                          min={bookingStartDate || today}
                                          max={eventEndDate}
                                          disabled={!bookingStartDate}
                                          onChange={(e) =>
                                              handleDateChange("booking_end_date", e.target.value)
                                          }
                                      />
                                  </Form.Item>
                              </Col> */}

                                <Col span={24} md={6}>
                                    <Form.Item
                                        className="mb-0"
                                        label={`Email`}
                                        name="email"
                                        rules={[
                                            {
                                                type: "email",
                                                message: "Please enter a valid email address!",
                                            },
                                            {
                                                max: 255,
                                                message: "Email address not more then 255 characters!",
                                            },
                                            {
                                                required: true,
                                                message: "Please enter your email!",
                                            },
                                        ]}
                                    >
                                        <Input autoComplete="off" placeholder={`Enter Email`} />
                                    </Form.Item>
                                </Col>

                                <Col span={24} md={6}>
                                    <Form.Item
                                        className="mb-0"
                                        label={`Total Seats`}
                                        name="total_seat"
                                        rules={[
                                            {
                                                required: true,
                                                message: "Total Seats must be provided!",
                                            },
                                        ]}
                                    >
                                        <InputNumber
                                            min={1}
                                            autoComplete="off"
                                            placeholder={`Enter Total Seats`}
                                        />
                                    </Form.Item>
                                </Col>

                                <Col span={24} md={12}>
                                    <Form.Item
                                        label={
                                            "Upload Event image for Web (Size [width x height] : 1130 x 640)"
                                        }
                                        name="image"
                                    >
                                        <SingleImageUpload
                                            value={image}
                                            fileType={FileType}
                                            imageType={"banner"}
                                            btnName={"Thumbnail"}
                                            onChange={(data) => handleImage(data)}
                                        />
                                    </Form.Item>
                                    {file && file.length > 0 && (
                                        <div className="mt-2">
                                            {" "}
                                            <Image
                                                width={120}
                                                src={
                                                    file && file.length > 0 && file !== "" ? file : notfound
                                                }
                                            ></Image>{" "}
                                        </div>
                                    )}
                                </Col>

                                <Col span={24} md={8} lg={4}>
                                    <Form.Item
                                        name="is_free"
                                        className="aligin-center"
                                        valuePropName="checked"
                                    >
                                        <label>Is Free Event?</label>
                                        <br />
                                        <Switch
                                            style={{ marginTop: 12 }}
                                            checked={isFree}
                                            onChange={handleIsFreeChange}
                                        />
                                    </Form.Item>
                                </Col>

                                {!isFree ? (
                                    <Col span={24} md={8}>
                                        <Form.Item
                                            className="mb-0"
                                            label={`Booking Fees`}
                                            name="booking_fees"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Booking Fees must be provided!",
                                                },
                                            ]}
                                        >
                                            <InputNumber
                                                min={0}
                                                autoComplete="off"
                                                placeholder={`Enter Booking Fees`}
                                            />
                                        </Form.Item>
                                    </Col>
                                ) : null}

                                <Col span={24} md={24}>
                                    <Form.Item
                                        label={`Event Description`}
                                        name="description"
                                        rules={[
                                            { required: true, message: `Enter Event Description` },
                                        ]}
                                    >
                                        <DescriptionEditor
                                            value={editorValue}
                                            placeholder={`Enter Event Description`}
                                            onChange={(data) => handleEditorChange(data)}
                                        />
                                    </Form.Item>
                                </Col>

                                <Col span={24} md={24}>
                                    <Form.Item name="tnc" valuePropName="checked" className="m-0">
                                        <div className="main-new-test-jkluhb">
                                            <label>Do you want to set terms and conditions?</label>
                                            <br />
                                            <Switch checked={tnc} onChange={handleTncChange} />
                                        </div>
                                    </Form.Item>
                                </Col>

                                {tnc ? (
                                    <Col span={24} sm={24}>
                                        <Form.Item
                                            label={`Event Terms & Conditions`}
                                            name="tnc_description"
                                            rules={[
                                                { required: true, message: `Enter Event Description` },
                                            ]}
                                        >
                                            <DescriptionEditor
                                                value={editorValue2}
                                                placeholder={`Enter Terms & Conditions`}
                                                onChange={(data) => handleEditorChange2(data)}
                                            />
                                        </Form.Item>
                                    </Col>
                                ) : null}

                                <Col span={24} md={24} className="mt-3">
                                    <Form.Item
                                        label="Location (Drag Marker for Selecting Location)"
                                        name="location"
                                        rules={[
                                            {
                                                required: true,
                                                message: "Please select the location!",
                                            },
                                        ]}
                                    >
                                        <LocationMap
                                            className="mt-3"
                                            onChange={handleLocationChange}
                                        // userData={data && data}
                                        />
                                    </Form.Item>
                                </Col>

                                <Col span={24}>
                                    <div className="card-btn">
                                        <Button onClick={handleCancle}>Cancel</Button>
                                        <Button htmlType="submit">Add Event</Button>
                                    </div>
                                </Col>
                            </Row> : null}

                    </Form>
                </Card>
            </div>
        </>
    );
}

export default Index;
