import { Row, Col, Card, Table, Button, Tooltip, Input, Tag, DatePicker, Avatar, Image, Form, Modal } from "antd";
import React, { useState, useEffect } from "react";
import ShowTotal from "../../../components/ShowTotal";
import useRequest from "../../../hooks/useRequest";
import { ShowToast, Severty } from "../../../helper/toast";
import useDebounce from "../../../hooks/useDebounce";
import moment from 'moment';
import { useNavigate } from "react-router";
import apiPath from "../../../constants/apiPath";
import ImportForm from "../../../components/ImportForm";
import UserFrom from "../UserFrom";
import { DownloadExcel, SampleFileDownload } from "../../../components/ExcelFile";
import ConfirmationBox from "../../../components/ConfirmationBox";
import ConfirmationDelete from "../../../components/ConfirmationDelete";
import StudentForm from "../StudentForm";
import { InboxOutlined } from "@ant-design/icons";
import Dragger from "antd/lib/upload/Dragger";
import sampleFile from "./sample.xlsx";
import * as XLSX from "xlsx";
const Search = Input.Search;
const { RangePicker } = DatePicker;

function Index() {

  const sectionName = "Student";
  const routeName = "student";

  const api = {
    status: apiPath.statusStudent,
    delete: apiPath.deleteStudent,
    addEdit: apiPath.addEditStudent,
    list: apiPath.listStudent,
    importFile: apiPath.importStudent
  }

  const [searchText, setSearchText] = useState('');
  const { request } = useRequest();
  const { showConfirm } = ConfirmationBox();
  const { confirmDelete } = ConfirmationDelete();
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [visible, setVisible] = useState(false);
  const [importVisible, setImportVisible] = useState(false);
  const [selected, setSelected] = useState();
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [error, setError] = useState();
  const [form] = Form.useForm();
  const [importFile, setImportFile] = useState([]);
  const [file, setFile] = useState([]);
  const [pagination, setPagination] = useState({ current: 1, pageSize: 10 });
  const debouncedSearchText = useDebounce(searchText, 300);
  const navigate = useNavigate();

  const view = (id) => {
    navigate(`/user/${routeName}/view/${id}`)
  }

  const activity = (id) => {
    navigate(`/user/activity/${id}`)
  }



  const [uploadModal, setUploadModal] = useState(false);
  const showUploadModal = () => {
    setUploadModal(true);
  };
  const handleOk01 = () => {
    setUploadModal(false);
  };
  const handleCancel01 = () => {
    setUploadModal(false);
  };
  const fileType = [
    ".csv",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    "application/vnd.ms-excel",
  ];

  const handleImport = (info) => {
    const { file, fileList } = info;
    console.log("-----------------:: ", info);
    if (fileList?.length) {
      if (!fileType.includes(file.type)) {
        setError("File format is not correct");
        return false;
      }
      setError();

      const fileData = {
        lastModified: file.lastModified,
        lastModifiedDate: file.lastModifiedDate,
        name: file.name,
        uid: file.uid,
        status: "done",
        originFileObj: file.originFileObj,
        size: file.size,
        type: file.type,
        percent: file.percent,
      };

      setFile([fileData]);
      parseExcel(file.originFileObj);
    } else {
      setError("Missing Excel File.");
    }
  };

  const onImport = () => {
    if (error) {
      return;
    }
    if (file?.length) {
      setLoading(true);
      const payload = {};
      payload.import_file = importFile;
      request({
        url: "/admin/student/import-file",
        method: "POST",
        data: payload,
        onSuccess: (data) => {
          setLoading(false);
          if (data.status) {
            ShowToast(data.message, Severty.SUCCESS);
            // hide();
            fetchData({ ...pagination, current: 1 });
            setUploadModal(false);
            // refresh();
          } else {
            ShowToast(data.message, Severty.ERROR);
          }
        },
        onError: (error) => {
          console.log(error);
          ShowToast(error.response.data.errors[0].message, Severty.ERROR);
          setLoading(false);
        },
      });
    } else {
      setError("Please Select an Excel File.");
    }
  };

  const parseExcel = (file) => {
    const reader = new FileReader();

    reader.onload = (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: "array" });
      const firstSheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[firstSheetName];

      // Parse the worksheet data into an array of objects
      const parsedData = XLSX.utils.sheet_to_json(worksheet);

      // Filter data based on some conditions like below
      const filteredData = parsedData.filter((row) => {
        console.log(row, "row")
        const email = row.email;
        const country_code = row.countryCode;
        const mobile_number = row.mobileNumber;
        const name = row.name;

        const shouldExclude = () => {
          let exclude = false;

          // Check conditions for name (case-insensitive)
          // if (
          //   name &&
          //   [
          //     "Brand",
          //     "Category",
          //     "Product",
          //     "Service",
          //     "Vehicle Make",
          //     "Vehicle Model",
          //     "Vehicle Type",
          //     "Vehicle Variant",
          //   ]?.includes(sectionName)
          // ) {
          //   const isDuplicateName = existingData?.some(
          //     (existingRow) =>
          //       existingRow?.name?.toLowerCase()?.trim() ===
          //       name?.toLowerCase()?.trim()
          //   );
          //   exclude = exclude || isDuplicateName;
          // }

          // Check conditions for email
          // if (email) {
          //   const isDuplicateEmail = existingData.some(
          //     (existingRow) =>
          //       existingRow.email.toLowerCase() === email.toLowerCase()
          //   );
          //   exclude = exclude || isDuplicateEmail;
          // }

          // // Check conditions for country_code and mobile_number together
          // if (country_code && mobile_number) {
          //   const isMatchingCountryMobile = existingData.some(
          //     (existingRow) =>
          //       existingRow.country_code === country_code &&
          //       existingRow.mobile_number === mobile_number
          //   );
          //   exclude = exclude || isMatchingCountryMobile;
          // }

          return exclude;
        };

        return !shouldExclude();
      });

      // Extract the remaining rows and set to setImportFile state
      setImportFile(filteredData);

      // Excluded rows and download the excel file with name of duplicate
      const excludedRows = parsedData.filter(
        (row) => !filteredData.includes(row)
      );
      if (excludedRows && excludedRows.length > 0) {
        const name = "Duplicate" + "-" + sectionName;
        // DownloadExcel(excludedRows, name)
      }
    };

    reader.readAsArrayBuffer(file);
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (_, { name, _id, image }) => {
        return (
          !image ?
            <>
              <Avatar style={{ backgroundColor: "#00a2ae", verticalAlign: 'middle' }} className="cap" size={40}> {name} </Avatar>
              <a style={{ marginLeft: 12 }} className="cap">{name}</a>
            </> :
            <>
              <Image className="image-index-radius" src={apiPath.assetURL + image} />
              <a style={{ marginLeft: 12 }} className="cap">{name}</a>
            </>
        );
      },
      sorter: (a, b) => {
        let nameA = a.name.toLowerCase()
        let nameB = b.name.toLowerCase();
        if (nameA < nameB)
          return -1;
        if (nameA > nameB)
          return 1;
        return 0;
      },
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      render: (_, { email }) => {
        return email ? email : '-';
      },
      sorter: (a, b) => {
        let nameA = a.email.toLowerCase()
        let nameB = b.email.toLowerCase();
        if (nameA < nameB)
          return -1;
        if (nameA > nameB)
          return 1;
        return 0;
      },
    },
    {
      title: "Guardian",
      dataIndex: "guardian_id",
      key: "guardian_id",
      render: (_, { guardian_id }) => {
        return guardian_id?.name ? guardian_id.name : '-';
      },
      sorter: (a, b) => {
        let nameA = a.email.toLowerCase()
        let nameB = b.email.toLowerCase();
        if (nameA < nameB)
          return -1;
        if (nameA > nameB)
          return 1;
        return 0;
      },
    },
    // {
    //   title: "Mobile Number",
    //   render: (_, { mobile_number, country_code }) => {
    //     return (
    //       (country_code ? "+" + country_code + "-"
    //         : "+965") + (mobile_number ? mobile_number : '')
    //     );
    //   },
    //   sorter: (a, b) => {
    //     let nameA = a.mobile_number;
    //     let nameB = b.mobile_number;
    //     if (nameA < nameB)
    //       return -1;
    //     if (nameA > nameB)
    //       return 1;
    //     return 0;
    //   },
    // },
    // {
    //   title: "Verified",
    //   key: "is_verify",
    //   filters: [
    //     {
    //       text: 'Verified',
    //       value: true,
    //     },
    //     {
    //       text: 'Not Verified',
    //       value: false,
    //     },
    //   ],
    //   render: (_, { is_verify, _id }) => {
    //     let color = is_verify ? 'cyan' : 'red';
    //     const type = "is_verify";
    //     return (
    //       <a>
    //         <Tag onClick={(e) => is_verify ? null : showConfirm({ record: _id, path: api.status, onLoading: () => setLoading(true), onSuccess: () => setRefresh(prev => !prev), type })} color={color} key={is_verify}> {is_verify ? "Verified" : "Not Verified"} </Tag>
    //       </a>
    //     );
    //   },
    // },
    {
      title: "Status",
      key: "is_active",
      filters: [
        {
          text: 'Active',
          value: true,
        },
        {
          text: 'Inactive',
          value: false,
        },
      ],
      render: (_, { is_active, _id }) => {
        let color = is_active ? 'green' : 'red';
        return (<a><Tag onClick={(e) => showConfirm({ record: _id, path: api.status, onLoading: () => setLoading(true), onSuccess: () => setRefresh(prev => !prev) })} color={color} key={is_active}>{is_active ? "Active" : "Inactive"}</Tag></a>);
      },
    },

    {
      title: "Registered On",
      key: "created_at",
      dataIndex: "created_at",
      render: (_, { created_at }) => {
        return (
          moment(created_at).format('DD-MMM-YYYY')
        );
      },
      sorter: (a, b) => {
        let nameA = a.created_at;
        let nameB = b.created_at;
        if (nameA < nameB)
          return -1;
        if (nameA > nameB)
          return 1;
        return 0;
      },
    },
    {
      title: "Action",
      fixed: 'right',
      render: (_, record) => {
        return (
          <>
            <Tooltip title={"Update " + sectionName} color={"purple"} key={"update" + routeName}>
              <Button onClick={() => {
                setSelected(record)
                setVisible(true)
              }}>
                <i className="fa fa-light fa-pen"></i>
              </Button>
            </Tooltip>

            {/* <Tooltip title={"View " + sectionName} color={"purple"} key={"view" + routeName}><Button onClick={() => view(record._id)}><i className="fa fa-light fa-eye"></i></Button></Tooltip> */}

            <Tooltip title={"Delete " + sectionName} color={"purple"} key={"delete" + routeName}>
              <Button onClick={(e) => confirmDelete({ record: record._id, path: api.delete, onLoading: () => setLoading(true), onSuccess: () => setRefresh(prev => !prev) })} >
                <i className="text-danger fas fa-trash"></i>
              </Button>
            </Tooltip>

          </>
        );
      },
    },
  ];


  useEffect(() => {
    setLoading(true)
    fetchData(pagination)
  }, [refresh, debouncedSearchText, startDate, endDate])

  const fetchData = (pagination, filters) => {
    const filterActive = filters ? filters.is_active : null;
    const filterVerify = filters ? filters.is_verify : null;
    const filterFeatured = filters ? filters.is_featured : null;
    request({
      url: api.list + `?verify=${filterVerify ? filterVerify.join(',') : ''}&featured=${filterFeatured ? filterFeatured.join(',') : ''}&status=${filterActive ? filterActive.join(',') : ''}&page=${pagination ? pagination.current : 1}&limit=${pagination ? pagination.pageSize : 10}&search=${debouncedSearchText}&start_date=${startDate ? startDate : ""}&end_date=${endDate ? endDate : ""}`,
      method: 'GET',
      onSuccess: (data) => {
        setLoading(false)
        setList(data.data.list.docs)
        setPagination(prev => ({ current: pagination.current, total: data.data.list.totalDocs }))
      },
      onError: (error) => {
        console.log(error)
        setLoading(false)
        ShowToast(error, Severty.ERROR)
      }
    })
  }

  const handleChange = (pagination, filters) => {
    fetchData(pagination, filters);
  }

  const onSearch = (e) => {
    setSearchText(e.target.value)
    setPagination({ current: 1 })
  };

  const handleChangeDate = (e) => {
    if (e != null) {
      setStartDate(moment(e[0]._d).format("YYYY-MM-DD"))
      setEndDate(moment(e[1]._d).format("YYYY-MM-DD"))
    } else {
      setStartDate()
      setEndDate()
    }
  };


  const excelData = list.map(row => ({
    "Name": row.name ? row.name : '-',
    "Email": row.email ? row.email : '-',
    "Status": row.is_active ? 'Active' : 'Inactive',
    "Registered On": moment(row.created_at).format("DD-MM-YYYY"),

  }));

  return (
    <>
      <div className="tabled dealerMain">
        <Row gutter={[24, 0]}>
          <Col xs="24" xl={24}>
            <Card
              bordered={false}
              className="criclebox tablespace mb-24"
              title={sectionName + " Management"}
              extra={
                <>
                  <div className="searchOuter">
                    <Search
                      className="mainSearch"
                      allowClear
                      size="large"
                      onChange={onSearch}
                      onPressEnter={onSearch}
                      value={searchText}
                      placeholder="Search By Name, Mobile, Email"
                    />

                    <RangePicker disabledDate={(current) => current.isAfter(Date.now())} onChange={handleChangeDate} />
                  </div>
                  <div className="button_group">
                    <Button className="d-inline-block" onClick={(e) => { setVisible(true); setSearchText(''); }}>Add {sectionName}</Button>
                    <Button className="d-inline-block" title="Export" onClick={(e) => DownloadExcel(excelData, sectionName)}><i class="fas fa-cloud-upload-alt"></i>&nbsp;&nbsp;Export</Button>
                    <Button className="d-inline-block" title="Import" onClick={showUploadModal}><i class="fas fa-cloud-download-alt"></i>&nbsp;&nbsp;Import</Button>

                  </div>
                </>
              }
            >

              <h4 className="text-right mb-1">{pagination.total ? ShowTotal(pagination.total) : ShowTotal(0)}</h4>
              <div className="table-responsive customPagination">
                <Table
                  loading={loading}
                  columns={columns}
                  dataSource={list}
                  pagination={{ defaultPageSize: 10, responsive: true, total: pagination.total, showSizeChanger: true, pageSizeOptions: ['10', '20', '30', '50'] }}
                  onChange={handleChange}
                  className="ant-border-space"
                />
              </div>
            </Card>
          </Col>
        </Row>
      </div>
      <Modal
        open={uploadModal}
        title="Import Excel"
        okText="Import"
        onCancel={handleCancel01}
        okButtonProps={{
          form: "create",
          htmlType: "submit",
          loading: loading,
        }}
      >
        <Form id="create" form={form} onFinish={onImport} layout="vertical">
          <Row>
            <Col span={24}>
              <Form.Item name="import_file">
                <div className="text-center">
                  <Button
                    title="Download Sample File"
                    href={sampleFile}
                    download="sample.xlsx"
                  // onClick={() => {
                  //   SampleFileDownload(sectionName);
                  //   console.log(sectionName);
                  // }}
                  >
                    <i class="fas fa-download"></i>&nbsp;&nbsp;Download Sample
                    File
                  </Button>
                </div>

                <div className="text-center mt-1 mb-2">
                  <span className="text-danger" style={{ fontWeight: "500" }}>
                    The column names in the sheet should match those given in
                    the sample file.
                  </span>
                </div>

                {/* <Divider orientation="center" orientationMargin={0} className="devider-color">OR</Divider> */}

                <Dragger
                  fileList={file}
                  onRemove={(e) => setFile([])}
                  maxCount={1}
                  accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                  onChange={handleImport}
                  customRequest={(e) => null}
                >
                  <p className="ant-upload-drag-icon">
                    {" "}
                    <InboxOutlined />{" "}
                  </p>
                  <p className="ant-upload-text">
                    Click or Drag File to This Area to Upload
                  </p>
                  <p className="ant-upload-hint">
                    Please Select an Excel File.
                  </p>
                </Dragger>
                {error && (
                  <div style={{ color: "red", padding: 5 }}>{error}</div>
                )}
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
      {visible && <StudentForm type={sectionName} path={api.addEdit} sectionName={sectionName} show={visible} hide={() => { setSelected(); setVisible(false) }} data={selected} refresh={() => setRefresh(prev => !prev)} />}

      {importVisible && <ImportForm path={api.importFile} sectionName={sectionName} show={importVisible} hide={() => setImportVisible(false)} existingData={list} refresh={() => setRefresh(prev => !prev)} />}

    </>
  );
}

export default Index;
