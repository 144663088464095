import { Row, Col, Radio, Modal, Form, Input, Select } from "antd";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import React, { useState, useEffect, useRef } from "react";
import useRequest from "../../hooks/useRequest";
import LocationMap from "./LocationMap";
import { ShowToast, Severty } from "../../helper/toast";

const UserFrom = ({ type, path, sectionName, show, hide, data, refresh }) => {
  const [form] = Form.useForm();
  const { request } = useRequest();
  const [loading, setLoading] = useState(false);
  const [mobileNumber, setMobileNumber] = useState({ mobile: '', country_code: '' });
  const [latLong, setlatLong] = useState({ lat: 30.5595, lng: 22.9375 });
  const [location, setLocation] = useState();
  const [dealIn, setDealIn] = useState('Products');
  const [userAddress, setUserAddress] = useState(null);

  const handleChange = (value, data, event, formattedValue) => {
    var country_code = data.dialCode;
    setMobileNumber({ country_code: country_code, mobile: value.slice(data.dialCode.length) });
  }

  const handleLocationChange = (value) => {
    setUserAddress(value.address);
    setlatLong({ lat: parseFloat(value.latitude), lng: parseFloat(value.longitude) });
  }

  const handleDealInChange = (value) => {
    setDealIn(value)
  }

  useEffect(() => {
    if (!data) return;
    form.setFieldsValue({ ...data });
    setlatLong({ lat: parseFloat(data.latitude), lng: parseFloat(data.longitude) });
    setLocation(data.location)
    setMobileNumber({
      mobile: data.mobile_number,
      country_code: data.country_code
    })
  }, [data])

  const onCreate = (values) => {
    if (!mobileNumber.mobile) return ShowToast('Please enter mobile number', Severty.ERROR)
    if (((mobileNumber.mobile).length < 8 || (mobileNumber.mobile).length > 12)) {
      return ShowToast('Mobile number should be between 8 to 12 digits', Severty.ERROR);
    }
    const { first_name, last_name, is_featured, email, product_type } = values
    setLoading(true);
    const payload = {}
    payload.country_code = mobileNumber.country_code;
    payload.mobile_number = mobileNumber.mobile;
    payload.first_name = first_name;
    payload.last_name = last_name;
    payload.name = first_name + " " + last_name;
    payload.email = email;
    payload.latitude = latLong.lat ? latLong.lat : null;
    payload.longitude = latLong.lng ? latLong.lng : null;
    payload.location = userAddress ? userAddress : location;

    request({
      url: `${data ? path + "/" + data._id : path}`,
      method: 'POST',
      data: payload,
      onSuccess: (data) => {
        setLoading(false);
        if (data.status) {
          ShowToast(data.message, Severty.SUCCESS);
          hide();
          refresh();
        } else {
          ShowToast(data.message, Severty.ERROR);
        }
      },
      onError: (error) => {
        ShowToast(error.response.data.message, Severty.ERROR);
        setLoading(false);
      },
    })
  };

  return (
    <Modal
      width={1100}
      open={show}
      title={`${data ? 'Update ' + sectionName : 'Create a New ' + sectionName}`}
      okText="Ok"
      onCancel={hide}
      okButtonProps={{
        form: 'create',
        htmlType: 'submit',
        loading: loading,
      }}
    >
      <Form id="create" form={form} initialValues={{ deal_in: "Products" }} onFinish={onCreate} layout="vertical">
        <Row gutter={[16, 16]}>
          <Col span={24} lg={8} sm={12}>
            <Form.Item className="mb-1"
              label="First Name"
              name="first_name"
              normalize={value => value.trimStart()}
              rules={[
                { required: true, message: "Please Enter the name!" },
                { max: 200, message: "First Name should not contain more then 200 characters!" },
                { min: 2, message: "First Name should contain at least 2 characters!" },
                { pattern: new RegExp(/^[a-z A-Z ]*$/), message: "Only Alphabetic Characters Allowed!" }
              ]}
            >
              <Input autoComplete="off" placeholder="Enter Name" />
            </Form.Item>
          </Col>

          <Col span={24} lg={8} sm={12}>
            <Form.Item className="mb-1"
              label="Last Name"
              name="last_name"
              normalize={value => value.trimStart()}
              rules={[
                { required: true, message: "Please Enter the last name!" },
                { max: 200, message: "Last Name should not contain more then 200 characters!" },
                { min: 2, message: "Last Name should contain at least 2 characters!" },
                { pattern: new RegExp(/^[a-z A-Z ]*$/), message: "Only Alphabetic Characters Allowed!" }
              ]}
            >
              <Input autoComplete="off" placeholder="Enter Name" />
            </Form.Item>
          </Col>

          <Col span={24} lg={8} sm={12}>
            <Form.Item className="mb-1"
              label="Email Address"
              name="email"
              rules={[
                { type: 'email', message: 'The email is not a valid email!' },
                { required: true, message: "Please enter the email!" },
                { max: 500, message: "Email should not contain more then 500 characters!" },
                { min: 5, message: "Email should contain at least 5 characters!" },
                {
                  pattern: new RegExp(/^([a-zA-Z0-9._%-]*[a-zA-Z]+[a-zA-Z0-9._%-]*@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/),
                  message: "Enter valid email!"
                }
              ]}
            >
              <Input autoComplete="off" placeholder="Enter Email Address" />
            </Form.Item>
          </Col>

          {type !== 'Student' ?

            <Col span={24} lg={8} sm={12} className="flagMobileNumber">
              <Form.Item className="mb-1"
                label="Mobile Number">
                <PhoneInput inputProps={{
                  name: "mobile",
                  required: true,
                  autoFocus: false,
                  placeholder: "Enter Mobile Number"
                }}
                  isValid={(value, country) => {
                    if (value.match(/1234/)) {
                      return 'Invalid value: ' + value + ', ' + country.name;
                    } else if (value.match(/1234/)) {
                      return 'Invalid value: ' + value + ', ' + country.name;
                    } else {
                      return true;
                    }
                  }}
                  country={"za"} value={mobileNumber ? ((mobileNumber.country_code ? mobileNumber.country_code : "+27") + (mobileNumber.mobile ? mobileNumber.mobile : null)) : '+27'} onChange={handleChange} />
              </Form.Item>
            </Col>
            : null}

          {/* <Col md={24}>
              <Form.Item
                label="Location (Drag Marker for Selecting Location)"
                name="location"
                rules={[
                  { required: true, message: "Please select the location!" },
                ]}
              >
                <LocationMap onChange={handleLocationChange} userData={data && data} />
              </Form.Item>
            </Col> */}

        </Row>
      </Form>
    </Modal>
  )
};

export default UserFrom;