import React, { useEffect, useState } from "react";
import useRequest from "../hooks/useRequest";
import apiPath from "../constants/apiPath";


const Privacy_policy = () => {
    const { request } = useRequest();
    const [description, setDescription] = useState();

    useEffect(() => {
        request({
            url: "common/content/privacy-policy-customer",
            method: "GET",
            onSuccess: (data) => {
                if (data.status) {
                    console.log(data)
                    if (data.data?.is_active) {
                        setDescription(data.data?.description)
                    } else {
                        setDescription("No Content Found!");
                    }
                } else {
                    setDescription("Something Went Wrong!!");
                }
            },
            onError: (err) => {
                console.log(err);
            }
        })
    }, [])

    return (
        <>
            <div className="mainWraper">
                <div className="contentWrapper sectionPading">
                    <div className="container">
                        <div className="sectionHeading">
                            <div className="row align-items-center">
                                <div className="col">
                                    <div className="title d-inline-block">
                                        <div className="txt">Privacy Policy of Pimpt Up</div>
                                        <div className="titleDesign d-flex align-items-center gap-2 mt-3"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="aboutOuter mt-2">
                            <div className="pera">
                                <div className='text-capitalize' dangerouslySetInnerHTML={{ __html: description }} />
                            </div>
                        </div>
                    </div>
                </div>

            </div>

        </>
    )
}

export default Privacy_policy;
