import { Layout, Row, Col } from "antd";
import { HeartFilled } from "@ant-design/icons";

function Footer() {
  const { Footer: AntFooter } = Layout;

  return (
    <AntFooter style={{ background: "#fafafa" }}>
      {/* <Row className="just">
        <Col xs={24} md={12} lg={12}> */}
      <div className="copyright">
        Copyright © 2024. All rights reserved
      </div>
      {/* </Col>
      </Row> */}
    </AntFooter>
  );
}

export default Footer;
