import {
    Row,
    Col,
    Card,
    Table,
    Button,
    Modal,
    Form,
    Input,
    Tag,
    DatePicker,
    Select,
    InputNumber,
    Tabs
} from "antd";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import React, { useState, useContext, useEffect, useRef } from "react";
import { ToTopOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { Link, useNavigate } from "react-router-dom";
import useRequest from "../hooks/useRequest";
import { ShowToast, Severty } from "../helper/toast";
import Main from "../components/layout/Main";
import useDebounce from "../hooks/useDebounce";
import { UploadOutlined, ExportOutlined } from "@ant-design/icons";
import * as XLSX from "xlsx";
import moment from "moment";
const { TabPane } = Tabs;

const { Option } = Select;
const { RangePicker } = DatePicker;
const Search = Input.Search;

function OrderManager() {
    const [pagination, setPagination] = useState({ current: 1, pageSize: 10 });
    const [searchText, setSearchText] = useState("");
    const [searchedColumn, setSearchedColumn] = useState("");
    const { request } = useRequest();
    const [list, setList] = useState([]);
    const [listExport, setListExport] = useState([]);
    const [loading, setLoading] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const [visible, setVisible] = useState(false);
    const [subscriptionVisible, setSubscriptionVisible] = useState(false);
    const [visibleView, setVisibleView] = useState(false);
    const [changeAdvisor, setChangeAdvisor] = useState(false);
    const [provideSession, setProvideSession] = useState(false);
    const [selected, setSelected] = useState();
    const [viewData, setViewData] = useState();
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    const [filter, setFilter] = useState();
    const [selectedValue, setSelectedValue] = useState();
    const debouncedSearchText = useDebounce(searchText, 300);
    const searchInput = useRef(null);
    const navigate = useNavigate();
    const { confirm } = Modal;
    const [activeTab, setActiveTab] = useState('All');

    const handleExport = () => {
        const data =
            listExport &&
            listExport.length > 0 &&
            listExport.map((row) => ({
                Name: row.name,

            }));

        const workbook = XLSX.utils.book_new();
        const worksheet = XLSX.utils.json_to_sheet(data);
        XLSX.utils.book_append_sheet(workbook, worksheet, "Transaction Data");
        XLSX.writeFile(
            workbook,
            `${moment().milliseconds() +
            1000 * (moment().seconds() + 60 * 60) +
            "-USER"
            }.xlsx`
        );
    };
    const onSearch = (e) => {
        setSearchText(e.target.value);
        setPagination({ current: 1 });
    };

    const statusChange = (record) => {
        setLoading(true);
        request({
            url: `/admin/car-make/status-change/${record}?`,
            method: "PUT",
            onSuccess: (data) => {
                setRefresh((prev) => !prev);
                setLoading(false);
            },
            onError: (error) => {
                console.log(error);
                ShowToast(error, Severty.ERROR);
            },
        });
    };
    const showConfirm = (record) => {
        setTimeout(() => {
            confirm({
                icon: <ExclamationCircleOutlined />,
                content: <Button>Are you sure you want change the status ? </Button>,
                onOk() {
                    statusChange(record);
                },
                onCancel() {
                    console.log("Cancel");
                },
            });
        }, 5);
    };

    const columns = [
        {
            title: "Booking No ",
            render: (_, { bookingNo }) => {
                return <div>{bookingNo ? bookingNo : "--"}</div>;
            },
        },
        {
            title: "User Name",
            render: (_, { user_id }) => {
                return <div>{user_id ? user_id.name : "--"}</div>;
            },
        },

        {
            title: "type",
            render: (_, { type }) => {
                return <div>{type}</div>;
            },
        },


        {
            title: "Centre Name",
            render: (_, { center_id }) => {
                return <div>{center_id ? center_id.name : "--"}</div>;
            }
        },
        // {
        //     title: "Total Amount",
        //     render: (_, { totalAmount }) => {
        //         return <div>{totalAmount ? totalAmount : 0.0}</div>;
        //     }
        // },
        {
            title: "Quantity",
            render: (_, { quantity, student }) => {
                return <div>{quantity ? quantity : student.length}</div>;
            }
        },
        {
            title: "Amount",
            render: (_, { totalAmount }) => {
                return <div>{totalAmount ? totalAmount.toFixed(2) : "--"}</div>;
            }
        },
        {
            title: "Booking date",
            key: "created_at",
            dataIndex: "created_at",
            render: (_, { created_at }) => {
                return new Date(created_at).toLocaleDateString();
            },
        },
        // {
        //     title: "Action",
        //     render: (_, record) => {
        //         return (
        //             <>
        //                 <div className="cta_wrap">
        //                     <a href={`/taxi-admin/activity/${record._id}`}>


        //                         <Button

        //                         >
        //                             <i class="fa fa-light fa-eye"></i>
        //                         </Button>
        //                     </a>








        //                 </div>
        //             </>
        //         );
        //     },
        // },


    ];
    const handleTabChange = (key) => {
        setActiveTab(key);
        setSelectedValue()
        // Add any other state updates or logic you need here
    };
    useEffect(() => {
        setLoading(true);
        fetchData(pagination);
    }, [refresh, debouncedSearchText, startDate, endDate, activeTab, selectedValue]);

    const handleChange = (pagination, filters) => {
        setFilter(filters.is_active);
        fetchData(pagination, filters);
    };
    const handleChangeStatus = (values) => {
        setSelectedValue(values)
    };
    const fetchData = (pagination, filters) => {
        const filterActive = filters ? filters.status : null;
        request({
            url: `/admin/order/list?tab=${activeTab ? activeTab : ''}&status=${filterActive ? filterActive.join(",") : ""
                }&page=${pagination ? pagination.current : 1}&limit=${pagination ? pagination.pageSize : 10
                }&search=${debouncedSearchText}&start_date=${startDate ? startDate : ""
                }&end_date=${endDate ? endDate : ""}&orderStatus=${selectedValue ? selectedValue : ''}`,
            method: "GET",
            onSuccess: (data) => {
                console.log(data)
                setLoading(false);
                setList(data.data.list.docs);
                setPagination((prev) => ({
                    current: pagination.current,
                    total: data.data.list.totalDocs,
                }));
            },
            onError: (error) => {
                console.log(error);
                ShowToast(error, Severty.ERROR);
            },
        });
    };
    const handleChangeDate = (e) => {
        setStartDate();
        setEndDate();
        if (e && e.length > 0) {
            setStartDate(moment(e[0]._d).format("YYYY-MM-DD"));
            setEndDate(moment(e[1]._d).format("YYYY-MM-DD"));
        }
    };

    return (
        <>
            <div className="tabled">
                <Row>
                    <Col xs="24" xl={24}>
                        <Card
                            bordered={false}
                            className="criclebox tablespace mb-24"
                            title="Booking Manager"
                            extra={
                                <>
                                    <Search
                                        size="large"
                                        onChange={onSearch}
                                        value={searchText}
                                        onPressEnter={onSearch}
                                    />
                                    <RangePicker onChange={handleChangeDate} />
                                    {/* {activeTab === "All" ?
                                        <Select
                                            style={{
                                                width: "50%",
                                            }}
                                            placeholder="Select Status"

                                            onChange={handleChangeStatus}
                                        >
                                            <Option value="InProgress">InProgress</Option>
                                            <Option value="Completed">Completed</Option>
                                            <Option value="Cancelled">Cancelled</Option>
                                            <Option value="On-the-Way">On The Way</Option>
                                            <Option value="Waiting">Waiting</Option>
                                        </Select> : null} */}
                                    {/* <Button
                                        type="primary"
                                        icon={<ExportOutlined />}
                                        onClick={handleExport}
                                    >
                                        Export
                                    </Button> */}

                                </>
                            }
                        >
                            <Tabs activeKey={activeTab} onChange={handleTabChange}>
                                <TabPane tab="All Booking" key="All">
                                    <div className="table-responsive">
                                        <Table
                                            loading={loading}
                                            columns={columns}
                                            dataSource={list}
                                            pagination={pagination}
                                            onChange={handleChange}

                                        />
                                    </div>
                                </TabPane>
                                <TabPane tab="Exam Booking" key="Exam">
                                    <div className="table-responsive">
                                        <Table
                                            loading={loading}
                                            columns={columns}
                                            dataSource={list}
                                            pagination={pagination}
                                            onChange={handleChange}

                                        />
                                    </div>
                                </TabPane>
                                <TabPane tab="Event Booking" key="Event">
                                    <div className="table-responsive">
                                        <Table
                                            loading={loading}
                                            columns={columns}
                                            dataSource={list}
                                            pagination={pagination}
                                            onChange={handleChange}

                                        />
                                    </div>
                                </TabPane>
                                <TabPane tab="Preparation Booking" key="Preparation">
                                    <div className="table-responsive">
                                        <Table
                                            loading={loading}
                                            columns={columns}
                                            dataSource={list}
                                            pagination={pagination}
                                            onChange={handleChange}

                                        />
                                    </div>
                                </TabPane>
                            </Tabs>
                        </Card>
                    </Col>
                </Row>
            </div>

        </>
    );
}








export default OrderManager;
