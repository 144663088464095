
import React, { useEffect, useState } from "react";
import Main from "../components/layout/Main";
import { useLocation } from "react-router";
import useRequest from "../hooks/useRequest";
// import LogoImg from "../assets/images/logo.png";

import { Severty, ShowToast } from "../helper/toast";
import Loader from "../components/Loader";
const ReturnUrl = () => {

    const { request } = useRequest()

    const location = useLocation()
    const data = location.search
    const searchParams = new URLSearchParams(location.search)
    const bookingId = searchParams.get('bookingId');
    const userId = searchParams.get('userId');
    const [loading, setLoading] = useState(false)
    const [refresh, setRefresh] = useState(false)
    const payNow = () => {
        setLoading(true)
        request({
            url: `/app/payment/pay-now?bookingId=${bookingId}&userId=${userId}`,
            method: 'get',
            onSuccess: (data) => {
                setLoading(false)
                if (data.status) {
                    ShowToast(data.message, Severty.SUCCESS);
                } else {
                    ShowToast(data.message, Severty.ERROR);
                }

                // ShowToast("Payment Successfully", Severty.SUCCESS)
            },
            onError: (error) => {
                console.log(error)
                ShowToast(error, Severty.ERROR)
            }
        })
    }

    useEffect(() => {
        payNow()
    }, [bookingId])




    return (

        <>

            {/* <ToastContainer position="top-right" /> */}

            {loading ? <Loader /> :
                <div className="login-sec customer-form-main">
                    <div className="container p-0">
                        <div className="successPageOuter">
                            <div className="booking-form-inner succesOuter">
                                <div className="logo-wrap">
                                    {/* <img src={LogoImg} alt="flomatic-logo" /> */}
                                </div>
                                <div className="successCardContent">
                                    <div className="thank-you">
                                        <div className="icon">
                                            {/* <img src={ThankYou} alt="" /> */}
                                        </div>
                                        <div className="thankyou-cont">
                                            <h3>Your Payment has been  Successfully Done !</h3>
                                            <div className="pera">Your Payment has been  Successfully Done</div>
                                            {/* <NavLink to={"/login"} className="btn_primary">
                      Login
                    </NavLink> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>}
        </>




    )
}

export default ReturnUrl