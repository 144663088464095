import { Row, Col, Modal, Form, Input, Image } from "antd";
import React, { useState, useEffect } from "react";
import useRequest from "../../hooks/useRequest";
import { ShowToast, Severty } from "../../helper/toast";
import SingleImageUpload from "../../components/SingleImageUpload";
import notfound from "../../assets/images/not_found.png";
import LocationMap from "../User/LocationMap";
import { useParams } from "react-router";
import moment from "moment";


const AddFrom = ({ section, api, show, hide, data, refresh }) => {

    const [form] = Form.useForm();
    const { request } = useRequest()
    const [loading, setLoading] = useState(false)
    const { id } = useParams();

    const onCreate = (values) => {
        const { booking_start_date, booking_end_date, booking_late_date } = values
        setLoading(true)

        const payload = {}
        payload.center_id = id
        payload.exam_id = data?._id
        payload.booking_start_date = booking_start_date;
        payload.booking_end_date = booking_end_date;
        payload.booking_late_date = booking_late_date

        request({
            url: `${data ? api.addEdit + "/" + data._id : api.addEdit}`,
            method: 'POST',
            data: payload,
            onSuccess: (data) => {
                setLoading(false)
                if (data.status) {
                    ShowToast(data.message, Severty.SUCCESS)
                    hide()
                    refresh()
                } else {
                    ShowToast(data.message, Severty.ERROR)
                }
            },
            onError: (error) => {
                ShowToast(error.response.data.message, Severty.ERROR)
                setLoading(false)
            },
        })
    };

    useEffect(() => {
        if (!data) return;

        console.log(data, "data")

        data && data.booking_info && form.setFieldsValue({ ...data, booking_start_date: moment(data.booking_info.booking_start_date).format("MM-DD-YYYY") })
    }, [data])


    return (
        <Modal
            open={show}
            width={800}
            title={`${data ? 'Update ' + section : 'Create a New ' + section}`}
            okText="Ok"
            onCancel={hide}
            okButtonProps={{
                form: 'create',
                htmlType: 'submit',
                loading: loading,
            }}
        >
            <Form id="create" form={form} onFinish={onCreate} layout="vertical">
                <Row gutter={[16, 16]}>
                    <Col span={24} sm={12}>
                        <Form.Item
                            className="mb-0"
                            label={`Booking Start Date`}
                            name="booking_start_date"
                            rules={[
                                { required: true, message: `Please enter the booking start date!` },
                                ({ getFieldValue }) => ({
                                    validator(_, value) {
                                        if (!value || new Date(value) >= new Date()) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject(
                                            new Error('Booking start date cannot be in the past!')
                                        );
                                    },
                                }),
                            ]}
                        >
                            <Input
                                type="date"
                                autoComplete="off"
                                placeholder={`Enter Booking Start Date`}
                                min={new Date().toISOString().split("T")[0]} // Disable past dates in UI
                            />
                        </Form.Item>
                    </Col>

                    <Col span={24} sm={12}>
                        <Form.Item
                            className="mb-0"
                            label={`Booking End Date`}
                            name="booking_end_date"
                            rules={[
                                { required: true, message: `Please enter the booking end date!` },
                                ({ getFieldValue }) => ({
                                    validator(_, value) {
                                        if (!value || new Date(value) >= new Date(getFieldValue('booking_start_date'))) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject(
                                            new Error('Booking end date cannot be before the booking start date!')
                                        );
                                    },
                                }),
                            ]}
                        >
                            <Input
                                type="date"
                                autoComplete="off"
                                placeholder={`Enter Booking End Date`}
                            />
                        </Form.Item>
                    </Col>

                    <Col span={24} sm={12}>
                        <Form.Item
                            className="mb-0"
                            label={`Late Booking Date`}
                            name="booking_late_date"
                            rules={[
                                { required: true, message: `Please enter the late booking date!` },
                                ({ getFieldValue }) => ({
                                    validator(_, value) {
                                        if (!value || new Date(value) >= new Date(getFieldValue('booking_end_date'))) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject(
                                            new Error('Late booking date must be after the booking end date!')
                                        );
                                    },
                                }),
                            ]}
                        >
                            <Input
                                type="date"
                                autoComplete="off"
                                placeholder={`Enter Late Booking Date`}
                            />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    )
}

export default AddFrom;
