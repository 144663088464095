import { Row, Col, Card, Button, Skeleton, Avatar, Image, Tooltip, Table, Tag } from "antd";
import React, { useState, useEffect } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import useRequest from "../../../hooks/useRequest";
import { ShowToast, Severty } from "../../../helper/toast";
import apiPath from "../../../constants/apiPath";
import { Badge } from 'antd';
import moment from 'moment';
import notfound from "../../../assets/images/not_found.png";


function View() {

  const sectionName = "Guardian";
  const routeName = "customer";
  const params = useParams();
  const { request } = useRequest();
  const [list, setList] = useState({});
  const [student, setStudent] = useState([]);
  const [quotes, setQuotes] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const fetchData = (id) => {
    setLoading(true);
    request({
      url: apiPath.viewGuardian + "/" + id,
      method: 'GET',
      onSuccess: ({ data }) => {
        console.log(data);
        const { list, getList } = data
        setLoading(false);
        setList(list);
        setStudent(getList)

      },
      onError: (error) => {
        ShowToast(error, Severty.ERROR)
      }
    })
  }

  useEffect(() => {
    fetchData(params.id)
  }, [])

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (_, { name, _id, image }) => {
        return (
          !image ?
            <>
              <Avatar style={{ backgroundColor: "#00a2ae", verticalAlign: 'middle' }} className="cap" size={40}> {name.charAt(0)} </Avatar>
              <a style={{ marginLeft: 12 }} className="cap">{name}</a>
            </> :
            <>
              <Image className="image-index-radius" src={apiPath.assetURL + image} />
              <a style={{ marginLeft: 12 }} className="cap">{name}</a>
            </>
        );
      },
      sorter: (a, b) => {
        let nameA = a.name.toLowerCase()
        let nameB = b.name.toLowerCase();
        if (nameA < nameB)
          return -1;
        if (nameA > nameB)
          return 1;
        return 0;
      },
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      render: (_, { email }) => {
        return email ? email : '-';
      },
      sorter: (a, b) => {
        let nameA = a.email.toLowerCase()
        let nameB = b.email.toLowerCase();
        if (nameA < nameB)
          return -1;
        if (nameA > nameB)
          return 1;
        return 0;
      },
    },
    {
      title: "Registered On",
      key: "created_at",
      dataIndex: "created_at",
      render: (_, { created_at }) => {
        return (
          moment(created_at).format('DD-MMM-YYYY')
        );
      },
      sorter: (a, b) => {
        let nameA = a.created_at;
        let nameB = b.created_at;
        if (nameA < nameB)
          return -1;
        if (nameA > nameB)
          return 1;
        return 0;
      },
    },

  ];

  return (
    <>
      <Row gutter={16}>
        <Col span={24} xs={24}>
          <Card title={sectionName + " Details"}>

            {loading ? [1, 2, 3].map(item => <Skeleton active key={item} />) :
              <div className="view-main-list">

                <div className="view-inner-cls">
                  <h5>Image:</h5>
                  <h6>
                    {list && !list.image && list.image == '' ?
                      <Avatar style={{ backgroundColor: "#00a2ae", verticalAlign: 'middle' }} className="cap" size={50}>
                        {list?.name?.charAt(0)}
                      </Avatar>
                      :
                      <Image className="image-radius" src={apiPath.assetURL + list.image} />
                    }
                  </h6>
                </div>

                <div className="view-inner-cls">
                  <h5>Name:</h5>
                  <h6><span className="cap">{list && list.first_name ? list.first_name + " " + list.last_name : '-'}</span></h6>
                </div>

                <div className="view-inner-cls">
                  <h5>Email Address:</h5>
                  <h6>{list ? list.email : '-'}</h6>
                </div>

                <div className="view-inner-cls">
                  <h5>Phone Number:</h5>
                  <h6>{list ? '+' + list.country_code + '-' : '+965'}{list ? list.mobile_number : '-'}</h6>
                </div>

                <div className="view-inner-cls">
                  <h5>Location:</h5>
                  <h6>{list ? list.location : '-'}</h6>
                </div>

                <div className="view-inner-cls">
                  <h5>Status:</h5>
                  <h6>{list && list.is_active ? <Badge status="success" text="Active" /> : <Badge status="error" text="InActive" />}</h6>
                </div>

                <div className="view-inner-cls">
                  <h5>Registered On:</h5>
                  <h6>{list && list.created_at ? moment(list.created_at).format('DD-MMM-YYYY') : '-'}</h6>
                </div>

                <div className="view-inner-cls float-right">
                  <Link className="ant-btn ant-btn-primary" to={`/guardian`}>Back</Link>
                </div>

              </div>
            }

          </Card>
        </Col>
      </Row>

      <Card className="mt-3" title="Student">
        <div className="tabled">
          <Row gutter={[24, 0]}>
            <Col xs="24" xl={24}>
              <div className="table-responsive customPagination">
                <Table
                  loading={loading}
                  columns={columns}
                  dataSource={student}
                  pagination={true}
                  className="ant-border-space"
                />
              </div>
            </Col>
          </Row>
        </div>
      </Card>

      {/* <Card className="mt-3" title="Quotes">
        <div className="tabled">
          <Row gutter={[24, 0]}>
            <Col xs="24" xl={24}>
              <div className="table-responsive customPagination">
                <Table
                  loading={loading}
                  columns={columns}
                  dataSource={quotes}
                  pagination={true}
                  className="ant-border-space"
                />
              </div>
            </Col>
          </Row>
        </div>
      </Card> */}
    </>
  );
}


export default View;
