import { Row, Col, Card, Table, Button, Input, Tag, Tooltip, Image, Form, Modal } from "antd";
import React, { useState, useEffect } from "react";
import ShowTotal from "../../components/ShowTotal";
import useRequest from "../../hooks/useRequest";
import { ShowToast, Severty } from "../../helper/toast";
import apiPath from "../../constants/apiPath";
import moment from "moment";
import useDebounce from "../../hooks/useDebounce";
import ConfirmationBox from "../../components/ConfirmationBox";
import notfound from "../../assets/images/not_found.png";
import { DownloadExcel } from "../../components/ExcelFile";
import ImportForm from "../../components/ImportForm";
import AddFrom from "./Add";
import { useNavigate } from "react-router";
import { InboxOutlined } from "@ant-design/icons";
import Dragger from "antd/lib/upload/Dragger";
import sampleFile from "./sample.xlsx";
import * as XLSX from "xlsx";
const Search = Input.Search;
function Index() {
    const sectionName = "Centre";
    const routeName = "center";
    const api = {
        status: apiPath.statusCenter,
        addEdit: apiPath.addEditCenter,
        list: apiPath.listCenter
    }

    const { request } = useRequest()
    const [list, setList] = useState([])
    const [loading, setLoading] = useState(false)
    const [refresh, setRefresh] = useState(false)
    const [visible, setVisible] = useState(false);
    const [selected, setSelected] = useState();
    const [searchText, setSearchText] = useState('');
    const [importVisible, setImportVisible] = useState(false);
    const debouncedSearchText = useDebounce(searchText, 300);
    const [pagination, setPagination] = useState({ current: 1, pageSize: 10 });
    const { showConfirm } = ConfirmationBox();
    const navigate = useNavigate();
    const [error, setError] = useState();
    const [form] = Form.useForm();
    const [importFile, setImportFile] = useState([]);
    const [file, setFile] = useState([]);

    const columns = [
        {
            title: "No",
            dataIndex: "s_no",
            key: "s_no",
            render: (_, { }, index) => <span>{index + 1}</span>
        },
        {
            title: "Name",
            dataIndex: "name",
            key: "name",
            render: (_, { name }) => {
                return (
                    <span className="cap">{name ? name : '-'}</span>
                )
            },
            sorter: (a, b) => {
                let nameA = a.name.toLowerCase();
                let nameB = b.name.toLowerCase();
                if (nameA < nameB)
                    return -1;
                if (nameA > nameB)
                    return 1;
                return 0;
            },
        },
        {
            title: "Website Url",
            dataIndex: "web_url",
            key: "web_url",
            render: (_, { web_url }) => {
                return (
                    <span className="cap">{web_url ? web_url : '-'}</span>
                )
            },
            sorter: (a, b) => {
                let nameA = a.web_url.toLowerCase();
                let nameB = b.web_url.toLowerCase();
                if (nameA < nameB)
                    return -1;
                if (nameA > nameB)
                    return 1;
                return 0;
            },
        },
        // {
        //     title: "Centre Code",
        //     dataIndex: "center_code",
        //     key: "center_code",
        //     render: (_, { center_code }) => {
        //         return (
        //             <span className="cap">{center_code ? center_code?.toUpperCase() : '-'}</span>
        //         )
        //     },
        //     sorter: (a, b) => {
        //         let nameA = a.center_code.toLowerCase();
        //         let nameB = b.center_code.toLowerCase();
        //         if (nameA < nameB)
        //             return -1;
        //         if (nameA > nameB)
        //             return 1;
        //         return 0;
        //     },
        // },
        {
            title: "Profile Description",
            dataIndex: "profile_desc",
            key: "profile_desc",
            render: (_, { profile_desc }) => {
                return (
                    <span className="cap">{profile_desc ? profile_desc : '-'}</span>
                )
            },
        },
        {
            title: "Status",
            key: "is_active",
            filters: [
                {
                    text: 'Active',
                    value: true,
                },
                {
                    text: 'Inactive',
                    value: false,
                },
            ],
            render: (_, { is_active, _id }) => {
                let color = is_active ? 'green' : 'red';
                return (<a><Tag onClick={(e) => showConfirm({ record: _id, path: api.status, onLoading: () => setLoading(true), onSuccess: () => setRefresh(prev => !prev) })} color={color} key={is_active}>{is_active ? "Active" : "Inactive"}</Tag></a>);
            },
        },
        {
            title: "Created On",
            key: "created_at",
            dataIndex: "created_at",
            render: (_, { created_at }) => {
                return (
                    moment(created_at).format('DD-MMM-YYYY')
                );
            },
            sorter: (a, b) => {
                let nameA = a.created_at;
                let nameB = b.created_at;
                if (nameA < nameB)
                    return -1;
                if (nameA > nameB)
                    return 1;
                return 0;
            },
        },
        {
            title: "Action",
            fixed: 'right',
            render: (_, record) => {
                return (
                    <>
                        <Tooltip title={"Update " + sectionName} color={"purple"} key={"update" + routeName}>
                            <Button title="Edit" onClick={() => {
                                setSelected(record)
                                setVisible(true)
                            }}>
                                <i className="fa fa-light fa-pen"></i>
                            </Button>
                        </Tooltip>
                        <Tooltip title={"View Exams"} color={"purple"} key={"view" + routeName}>
                            <Button onClick={() => {
                                navigate(`/centre/${record?._id}/view-exams`)
                            }}>
                                <i className="fa fa-light fa-eye"></i>
                            </Button>
                        </Tooltip>
                    </>
                );
            },
        },
    ];
    const [uploadModal, setUploadModal] = useState(false);
    const showUploadModal = () => {
        setUploadModal(true);
    };
    const handleOk01 = () => {
        setUploadModal(false);
    };
    const handleCancel01 = () => {
        setUploadModal(false);
    };
    const fileType = [
        ".csv",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        "application/vnd.ms-excel",
    ];

    const handleImport = (info) => {
        const { file, fileList } = info;
        console.log("-----------------:: ", info);
        if (fileList?.length) {
            if (!fileType.includes(file.type)) {
                setError("File format is not correct");
                return false;
            }
            setError();

            const fileData = {
                lastModified: file.lastModified,
                lastModifiedDate: file.lastModifiedDate,
                name: file.name,
                uid: file.uid,
                status: "done",
                originFileObj: file.originFileObj,
                size: file.size,
                type: file.type,
                percent: file.percent,
            };

            setFile([fileData]);
            parseExcel(file.originFileObj);
        } else {
            setError("Missing Excel File.");
        }
    };

    const onImport = () => {
        if (error) {
            return;
        }
        if (file?.length) {
            setLoading(true);
            const payload = {};
            payload.import_file = importFile;
            request({
                url: "/admin/center/import-file",
                method: "POST",
                data: payload,
                onSuccess: (data) => {
                    setLoading(false);
                    if (data.status) {
                        ShowToast(data.message, Severty.SUCCESS);
                        // hide();
                        fetchData({ ...pagination, current: 1 });
                        setUploadModal(false);
                        // refresh();
                    } else {
                        ShowToast(data.message, Severty.ERROR);
                    }
                },
                onError: (error) => {
                    console.log(error);
                    ShowToast(error.response.data.errors[0].message, Severty.ERROR);
                    setLoading(false);
                },
            });
        } else {
            setError("Please Select an Excel File.");
        }
    };

    const parseExcel = (file) => {
        const reader = new FileReader();

        reader.onload = (e) => {
            const data = new Uint8Array(e.target.result);
            const workbook = XLSX.read(data, { type: "array" });
            const firstSheetName = workbook.SheetNames[0];
            const worksheet = workbook.Sheets[firstSheetName];

            // Parse the worksheet data into an array of objects
            const parsedData = XLSX.utils.sheet_to_json(worksheet);

            // Filter data based on some conditions like below
            const filteredData = parsedData.filter((row) => {
                const email = row.email;
                const country_code = row.countryCode;
                const mobile_number = row.mobileNumber;
                const name = row.name;

                const shouldExclude = () => {
                    let exclude = false;

                    // Check conditions for name (case-insensitive)
                    // if (
                    //   name &&
                    //   [
                    //     "Brand",
                    //     "Category",
                    //     "Product",
                    //     "Service",
                    //     "Vehicle Make",
                    //     "Vehicle Model",
                    //     "Vehicle Type",
                    //     "Vehicle Variant",
                    //   ]?.includes(sectionName)
                    // ) {
                    //   const isDuplicateName = existingData?.some(
                    //     (existingRow) =>
                    //       existingRow?.name?.toLowerCase()?.trim() ===
                    //       name?.toLowerCase()?.trim()
                    //   );
                    //   exclude = exclude || isDuplicateName;
                    // }

                    // Check conditions for email
                    // if (email) {
                    //   const isDuplicateEmail = existingData.some(
                    //     (existingRow) =>
                    //       existingRow.email.toLowerCase() === email.toLowerCase()
                    //   );
                    //   exclude = exclude || isDuplicateEmail;
                    // }

                    // // Check conditions for country_code and mobile_number together
                    // if (country_code && mobile_number) {
                    //   const isMatchingCountryMobile = existingData.some(
                    //     (existingRow) =>
                    //       existingRow.country_code === country_code &&
                    //       existingRow.mobile_number === mobile_number
                    //   );
                    //   exclude = exclude || isMatchingCountryMobile;
                    // }

                    return exclude;
                };

                return !shouldExclude();
            });

            // Extract the remaining rows and set to setImportFile state
            setImportFile(filteredData);

            // Excluded rows and download the excel file with name of duplicate
            const excludedRows = parsedData.filter(
                (row) => !filteredData.includes(row)
            );
            if (excludedRows && excludedRows.length > 0) {
                const name = "Duplicate" + "-" + sectionName;
                // DownloadExcel(excludedRows, name)
            }
        };

        reader.readAsArrayBuffer(file);
    };

    useEffect(() => {
        setLoading(true)
        fetchData(pagination)
    }, [refresh, debouncedSearchText])


    const fetchData = (pagination, filters) => {
        const filterActive = filters ? filters.is_active : null
        request({
            url: api.list + `?status=${filterActive ? filterActive.join(',') : ''}&page=${pagination ? pagination.current : 1}&limit=${pagination ? pagination.pageSize : 10}&search=${debouncedSearchText}`,
            method: 'GET',
            onSuccess: (data) => {
                setLoading(false)
                setList(data.data.list.docs)
                setPagination(prev => ({ current: pagination.current, total: data.data.list.totalDocs }))
            },
            onError: (error) => {
                setLoading(false)
                ShowToast(error, Severty.ERROR)
            }
        })
    }

    const onSearch = (e) => {
        setSearchText(e.target.value)
        setPagination({ current: 1 })
    };

    const handleChange = (pagination, filters) => {
        fetchData(pagination, filters);
    }



    const excelData = list.map(row => ({
        "Image": row.image ? row.image : '-',
        "Name": row.name ? row.name : '-',
        "Status": row.is_active ? 'Active' : 'Inactive',
        "Created On": moment(row.created_at).format("DD-MM-YYYY"),
        "Description": row.profile_desc,
        "Centre Code": row.center_code,
        "Location": row.location,
        Latitude: row.latitude,
        Longitude: row.longitude,
        Email: row.email,
        "Website URL": row.web_url,
    }));

    return (
        <>
            <div className="tabled categoryService">
                <Row gutter={[24, 0]}>
                    <Col xs="24" xl={24}>
                        <Card
                            bordered={false}
                            className="criclebox tablespace mb-24"
                            title={sectionName + " Management"}
                            extra={
                                <>
                                    <Search
                                        allowClear
                                        size="large"
                                        onChange={onSearch}
                                        value={searchText}
                                        onPressEnter={onSearch}
                                        placeholder="Search By Name"
                                    />
                                    <div className="button_group justify-content-end w-100">
                                        <Button onClick={(e) => { setVisible(true); setSearchText(''); }}>Add {sectionName}</Button>
                                        <Button title="Export" onClick={(e) => DownloadExcel(excelData, sectionName)}><i class="fas fa-cloud-upload-alt"></i>&nbsp;&nbsp;Export</Button>
                                        <Button title="Import" onClick={showUploadModal}><i class="fas fa-cloud-download-alt"></i>&nbsp;&nbsp;Import</Button>
                                    </div>
                                </>
                            }
                        >

                            <h4 className="text-right mb-1">{pagination.total ? ShowTotal(pagination.total) : ShowTotal(0)}</h4>
                            <div className="table-responsive customPagination">
                                <Table
                                    loading={loading}
                                    columns={columns}
                                    dataSource={list}
                                    pagination={{ defaultPageSize: 10, responsive: true, total: pagination.total, showSizeChanger: true, pageSizeOptions: ['10', '20', '30', '50'] }}
                                    onChange={handleChange}
                                    className="ant-border-space"
                                />
                            </div>
                        </Card>
                    </Col>
                </Row>
            </div>
            <Modal
                open={uploadModal}
                title="Import Excel"
                okText="Import"
                onCancel={handleCancel01}
                okButtonProps={{
                    form: "create",
                    htmlType: "submit",
                    loading: loading,
                }}
            >
                <Form id="create" form={form} onFinish={onImport} layout="vertical">
                    <Row>
                        <Col span={24}>
                            <Form.Item name="import_file">
                                <div className="text-center">
                                    <Button
                                        title="Download Sample File"
                                        href={sampleFile}
                                        download="sample.xlsx"
                                    // onClick={() => {
                                    //   SampleFileDownload(sectionName);
                                    //   console.log(sectionName);
                                    // }}
                                    >
                                        <i class="fas fa-download"></i>&nbsp;&nbsp;Download Sample
                                        File
                                    </Button>
                                </div>

                                <div className="text-center mt-1 mb-2">
                                    <span className="text-danger" style={{ fontWeight: "500" }}>
                                        The column names in the sheet should match those given in
                                        the sample file.
                                    </span>
                                </div>

                                {/* <Divider orientation="center" orientationMargin={0} className="devider-color">OR</Divider> */}

                                <Dragger
                                    fileList={file}
                                    onRemove={(e) => setFile([])}
                                    maxCount={1}
                                    accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                    onChange={handleImport}
                                    customRequest={(e) => null}
                                >
                                    <p className="ant-upload-drag-icon">
                                        {" "}
                                        <InboxOutlined />{" "}
                                    </p>
                                    <p className="ant-upload-text">
                                        Click or Drag File to This Area to Upload
                                    </p>
                                    <p className="ant-upload-hint">
                                        Please Select an Excel File.
                                    </p>
                                </Dragger>
                                {error && (
                                    <div style={{ color: "red", padding: 5 }}>{error}</div>
                                )}
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>


            {visible && <AddFrom section={sectionName} api={api} show={visible} hide={() => { setSelected(); setVisible(false) }} data={selected} refresh={() => setRefresh(prev => !prev)} />}
            {importVisible && <ImportForm path={api.importFile} sectionName={sectionName} show={importVisible} hide={() => setImportVisible(false)} existingData={list} refresh={() => setRefresh(prev => !prev)} />}
        </>
    );
}

export default Index;